import BaseProcessor from "../base/BaseProcessor";

export default class DiagRead extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.diagsReadCount = 0
        this.methods = {};
    }

    tryToApply(event)
    {
        if (this.contains(event, 'open task'))
        {
            return;
        }

        const mathod = event.message.match(/Diag\sread\s\(false,(&quot;|u0022)(.*)(&quot;|u0022)\)/);
        if (mathod && mathod[2])
        {
            const methodName = mathod[2];
            if (!this.methods[methodName]) {
                this.methods[methodName] = true;
                this.diagsReadCount++;
            }
        }
    }

    changingProblem()
    {
        this.events.push(
            {
                name: 'Методик запрошено',
                value: this.diagsReadCount
            }
        );

        this.diagsReadCount = 0;
        this.methods = {};
    }
}

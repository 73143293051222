import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Button, Container, Table} from "react-bootstrap";
import AnalyticsShort from "../../analytics/AnalyticsShort";

const UserReport = (props) => {
    const [data, setData] = useState([]);

    const anlyticsData = new AnalyticsShort();
    const [problems, setProblems] = useState([]);
    const [inviteName, setInviteName] = useState('');

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }

        if (time > 60 * 60* 1000)
        {
            const hours = Math.floor(time/(60 * 60 * 1000));
            const minutes = Math.floor( (time - hours * (60 * 60 * 1000)) / 60000);
            return hours + ' ч ' + minutes + ' м';
        }
        else
        {
            return (Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)').toString().replaceAll('.',',');

        }
    }

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:props.id, user_id:props.user_id } }
        );
        setInviteName(response.data.invite_name);
        setData(response.data);
        anlyticsData.fetch(response.data);
        setProblems(anlyticsData.getProblems());
    }
    useEffect(() => {
        fetchFenom();
    }, []);

    function convertToTaskName(page)
    {
        const task = data.invite_order.find(item => item.problem_id == page);
        return 'Задача ' + page  + '(' + task.id + ')'+ ' ' + task.title;
    }

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протокола...</h2></Container> :
                <Container>
                    <Container><Button onClick={()=>window.close()}>Закрыть</Button></Container>
                    <Container>
                        <Container><h2>Ваш краткий протокол решения задач</h2></Container>
                        <Container className={'text-start'}>
                            <Container>{inviteName} – {data.fio}</Container>
                            <Container>Дата заполнения начала прохождения – {data.login_time}</Container>
                            {problems && problems.length > 0 &&
                                <Container className={'text-center'}>
                                    <h3>
                                    <a href={'#'} onClick={() =>
                                    {
                                        let keys = [];
                                        const newData = [];
                                        problems.forEach(item =>
                                        {
                                            let row = {
                                                name: convertToTaskName(item.page)
                                            }
                                            item.events.forEach(sub =>
                                            {
                                                if (keys.indexOf(sub.name) < 0)
                                                {
                                                    keys.push(sub.name);
                                                }
                                                if (sub.time)
                                                {
                                                    row[sub.name] = (Math.round(sub.time / 6000) / 10).toString().replaceAll('.',',');
                                                    return;
                                                }
                                                if (sub.value)
                                                {
                                                    row[sub.name] = sub.value;
                                                    return;
                                                }
                                                row[sub.name] = '0';
                                            })
                                            newData.push(row);
                                        });
                                        keys = keys.filter(item=>item!=='Вход в программу');
                                        let csv = inviteName + ';' + data.fio + ';' + data.login_time + ';;\n';
                                        csv += 'Задача;' + keys.join(';') + '\n';
                                        newData.forEach(row => {
                                            csv += row.name + ';';
                                            keys.forEach(key => {
                                               csv += (row[key] == undefined?'0':row[key]) + ';';
                                            });
                                            csv += '\n';
                                        });

                                        function openWindowWithPost(url, data) {
                                            var form = document.createElement("form");
                                            form.target = "_blank";
                                            form.method = "POST";
                                            form.action = url;
                                            form.style.display = "none";

                                            for (var key in data) {
                                                var input = document.createElement("input");
                                                input.type = "hidden";
                                                input.name = key;
                                                input.value = data[key];
                                                form.appendChild(input);
                                            }
                                            document.body.appendChild(form);
                                            form.submit();
                                            document.body.removeChild(form);
                                        }
                                        openWindowWithPost(SERVER_URL + '/api/win1251.php', {
                                            filename: inviteName + '-' + data.fio + '-' + data.login_time + '.csv',
                                            content: csv
                                        });
                                    }}>Скачать</a>
                                    </h3>
                                </Container>
                            }
                        </Container>
                    </Container>

                    {problems.length > 0 && problems.map(prob =>
                    {
                        return <>
                            <Container className={'text-start mt-3'}>
                                <Container className={'row'}>
                                    <Container className={'text-start'}>{convertToTaskName(prob.page)}.</Container>
                                </Container>
                            </Container>

                            <Table bordered hover>
                                <thead>
                                <th>Событие</th>
                                <th>Время</th>
                                </thead>
                                <tbody>
                                {prob.events && prob.events.map(item =>
                                {
                                    return <>
                                        <tr>
                                            <td className={'col-8 text-start'}>{item.name}</td>
                                            <td className={'col-4 text-end'}>
                                                {
                                                    item.time && toTimeString(item.time)
                                                }
                                                {
                                                    item.value && item.value
                                                }
                                            </td>
                                        </tr>
                                    </>
                                })}
                                </tbody>
                            </Table>
                        </>
                    })
                    }


                    {
/*
                <Container className={'mt-4'}>
                    <Container><h2>Технический журнал</h2></Container>
                    <Container>
                        <Table bordered hover>
                            <thead>
                            <th>Событие</th>
                            <th>Время</th>
                            </thead>
                            <tbody>
                        {data.events && data.events
                            //.filter(item => item.message.indexOf('open task:') < 0)
                            .map((item, index) => {
                                return <tr>
                                        <td className={'col-8 text-start'}>{item.timestamp}</td>
                                        <td className={'col-4 text-start'}>{item.message}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </Container>
                </Container>
*/
                    }
              </Container>
        }</>
    );
}

export default UserReport

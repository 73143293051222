import {Container} from "react-bootstrap";
import log from "./Logger";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "./Config";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "editorjs-paragraph-with-alignment";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Delimiter from "@editorjs/delimiter";
import NestedList from "@editorjs/nested-list";
import Underline from "@editorjs/underline";
import AuthContext from "./context/AuthProvider";

const Instruction = () => {
    const { auth } = useContext(AuthContext);
    const [editor, setEditor] = useState(null);

    async function fetchData() {
        const responseFenom = await axios.post(SERVER_URL + '/api/instruction.php',
            { type: auth.invite_type }
        );
        const cnt = responseFenom.data;
        setEditor(new EditorJS({
            holder: 'editor_instruct_view',
            minHeight : 5,
            data: cnt,
            readOnly: true,
            tools: {
                header: Header,
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                },
                table: Table,
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: '/api/upload_image.php',
                            byUrl:  '/api/upload_url.php'
                        }
                    }
                },
                delimiter: Delimiter,
                nestedList: NestedList,
                underline: Underline
            }
        }));
    }

    useEffect(() => {
        fetchData()
    }, []);

    log('View Instruction');

    return (
        <Container>
            <div id={'editor_instruct_view'}></div>
        </Container>
    );
}

export default Instruction

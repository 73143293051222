import {Button, Container, Form} from "react-bootstrap";
import {useState} from "react";
import DynamicText from "../DynamicText";

const AdminEditFenom = (props) => {

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [editId, setEditId] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const handleTitleChange = event => {
        setTitle(event.target.value);
    }

    const handleDescChange = event => {
        setDesc(event.target.value);
    }

    const handleClickEditCurrent = (id) => {
        const item = props.fenomData.find((itm) => itm.id === id);
        setTitle(item.title);
        setDesc(item.description);
        setEditId(id);
    }

    const handleClickDelete = (id) => {
        props.fenomData.forEach(item => {
            if (item.id === id) {
                if (item.deleted === 1)
                {
                    item.deleted = undefined;
                    delete item.deleted;
                } else {
                    item.deleted = 1;
                }
            }
        });

        setTitle('');
        setDesc('');
        setEditId(null);
        setRefresh(refresh + 1);
    }

    const handleClick = () => {
        if (editId != null)
        {
            props.fenomData.forEach(item => {
                if (item.id === editId) {
                    item.edited = 1;
                    item.title = title;
                    item.description = desc;
                }
            });

            setTitle('');
            setDesc('');
            setEditId(null);
            window.scrollTo(0,0,);
        }
        else //add mode
        {
            const newObject = {
                new: 1,
                id: 'new-' + Math.round(Math.random() * 0x0fffffff),
                title: title,
                description: desc
            };
            props.fenomData.push(newObject);
            setTitle('');
            setDesc('');
            window.scrollTo(0,0,);
        }
    }

    return (
        <Container>
            {refresh != -1 && <> {
                props.fenomData.map((item, index) =>
                    <Container className={'my-4'}>
                        <Container className={'text-start'}>
                            <h5>{item.title}
                                &nbsp;<a href={'#edit-fenom-current'} onClick={() => handleClickEditCurrent(item.id)}>Редактировать</a>
                                &nbsp;<a href={'#'} className={'text-danger'} onClick={() => handleClickDelete(item.id)}>
                                    {item.deleted == 1 ?
                                        'Отменить удаление (будет удален)'
                                    :
                                        'Удалить'
                                    }</a>
                            </h5>
                        </Container>
                        <Container>
                            <div className={'text-start'}
                                 dangerouslySetInnerHTML={{__html: item.description}}></div>
                        </Container>
                    </Container>
                )
            }</>
            }
            <Container id={"edit-fenom-current"} className={'border bg-info'}>
                <Form.Group className="mb-3">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control as="textarea" rows={5} id={'ad_title'} onChange={handleTitleChange} value={title}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control as="textarea" rows={12} id={'ad_pred_metod'} onChange={handleDescChange} value={desc}/>
                </Form.Group>
            </Container>
            <Container>
                <Button className={'mx-1 my-2 btn-success'} onClick={handleClick}>{editId? "Сохранить": "Добавить"}</Button>
            </Container>

        </Container>
    );
}

export default AdminEditFenom

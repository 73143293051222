import HypotheseWrite from "../hypos/HypotheseWrite";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import {SERVER_URL} from "../../../Config";
import log from "../../../Logger";

const DiagCheck = () => {
    const { setTask, task } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);

    const [data, setData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/diagnos_const.php',
            { }
        );
        setData(response.data.map(item => item.title));
        setLoaded(true);

        setTask({...task, diagFullAnswers: response.data });

        log('DiagCheck, loaded');
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>{loaded && <HypotheseWrite
            titleName={'Диагностическое заключение'}
            descText={'Введите заключение в краткой форме для автопроверки программой'}
            placeholder={'Начните вводить краткое название элемента и направление смещения'}
            previewText={task.diagSummary}
            alwaysActive={true}
            data={data}
            whereSave={'diagCheck'}
        ></HypotheseWrite>}</>
    );
}

export default DiagCheck

import React from "react";
import {Chart} from "react-google-charts";
import {Container} from "react-bootstrap";

export default function LineChart(props)
{
    const options = {
        lineWidth: 5,
        series: {
            1: { curveType: "function" },
        },
        legend: "none",
        pointSize: 12
    };

    let count = 1;
    const data = [];
    data.push(["Этапы", ""]);
    if (props.valueEvents)
    {
        props.valueEvents.map(item =>
        {
            if (props.numbers)
            {
                data.push([count,item.value]);
            } else {
                data.push([item.name,item.value]);
            }
            count++;
        });
    }
    else
    {
        props.events.map(item =>
        {
            if (props.numbers)
            {
                data.push([count, Math.round(item.time / 6000) / 10]);
            }
            else
            {
                data.push([item.name, Math.round(item.time / 6000) / 10]);
            }
            count++;
        });
    }

    return (
        <Container>
            <Container className={'text-center'}>
                <h4>{props.page && 'Задача '+ props.page}
                    &nbsp;
                <a href={"javascript:void(0)"} onClick={()=>{
                    navigator.clipboard.writeText(data.map(item=>item.join('\t').replaceAll('.', ',')).join('\n'));
                }}>Скопировать данные</a>
                </h4>
            </Container>
            <Chart
                chartType="LineChart"
                data={data}
                options={options}
                width="100%"
                height="400px"
            />
        </Container>
    );
}

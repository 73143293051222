import {Button, Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import DiagSummaryWrite from "./diagSummary/DiagSummaryWrite";
import DiagCheck from "./diagSummary/DiagCheck";
import log from "../../Logger";

const DiagSummary = () => {
    const { setTask, task } = useContext(AuthContext);

    return (
        <>
            {
                task && task.number === 1 ?<>
                    <DiagSummaryWrite></DiagSummaryWrite>
                    <Container className={'my-4'}>
                        <Button className={'float-start'} onClick={() => {
                            log('DiagSummary, click back');
                            setTask({...task, chapter: 0, number: 1});
                        }}>Назад</Button>
                        <Button className={'align-middle'} onClick={() => {
                            log('DiagSummary, click main menu');
                            setTask({...task, chapter: 0, number: 1});
                        }}>Главное меню</Button>
                        <Button className={'float-end'} onClick={() => {
                            log('DiagSummary, click next');
                            setTask({...task, chapter: 4, number: 2});
                        }}>Далее</Button>
                    </Container>
                </>: ''
            }
            {
                task && task.number === 2 ? <>
                    <DiagCheck></DiagCheck>
                    <Container className={'my-4'}>
                        <Button className={'float-start'} onClick={() => {
                            log('DiagSummary2, click back');
                            setTask({...task, chapter: 4, number: 1});
                        }}>Назад</Button>
                        <Button className={'align-middle'} onClick={() => {
                            log('DiagSummary2, main menu');
                            setTask({...task, chapter: 0, number: 1});
                        }}>Главное меню</Button>
                        <Button className={'float-end'} onClick={() => {
                            log('DiagSummary2, apply');
                            setTask({...task, chapter: 0, number: 1, checkFinalDiagnos: true});
                        }}>Принять</Button>
                    </Container>
                </> : ''
            }
        </>
    );
}

export default DiagSummary

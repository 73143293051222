import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Button, Container, Table} from "react-bootstrap";
import AnalyticsMoves from "../../analytics/AnalyticsMoves";
import LineChart from "../LineChart";

const MovesReport = (props) => {
    const [data, setData] = useState([]);

    const anlyticsData = new AnalyticsMoves();
    const [problems, setProblems] = useState([]);
    const [inviteName, setInviteName] = useState('');

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }
        return Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)';
    }

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:props.id, user_id:props.user_id } }
        );
        setInviteName(response.data.invite_name);
        setData(response.data);
        anlyticsData.fetch(response.data);
        setProblems(anlyticsData.getProblems());
    }

    useEffect(() => {
        fetchFenom();
    }, []);

    function convertToTaskName(page)
    {
        const task = data.invite_order.find(item => item.problem_id == page);
        return 'Задача ' + page + '(' + task.id + ')'+ ' ' + task.title;
    }

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протокола...</h2></Container> :
                <Container>
                    <Container><Button onClick={()=>window.close()}>Закрыть</Button></Container>
                    <Container>
                        <Container><h3>Гуляние по этапам</h3></Container>
                        <Container className={'text-start'}>
                            <Container>{inviteName} – {data.fio}</Container>
                            <Container>Дата заполнения начала прохождения – {data.login_time}</Container>
                        </Container>
                    </Container>

                    {problems.length > 0 && problems.map(prob =>
                    {
                        return <>
                            <Table bordered hover>
                                <tbody>
                                <tr>
                                    <td>{convertToTaskName(prob.page)}</td>
                                {prob.events && prob.events.map(item =>
                                {
                                    return <>{item.time > 0?<td className={'col-8 text-start'}>{item.name}</td>:''}</>
                                })}
                                </tr>
                                </tbody>
                            </Table>
                        </>
                    })
                    }
                    {problems.length > 0 && problems.map(prob =>
                    {
                        return <Container><LineChart events={prob.events} page={prob.page}></LineChart></Container>;
                    })}

                    {/*
                        <Container className={'mt-4'}>
                            <Container><h2>Технический журнал</h2></Container>
                            <Container>
                                <Table bordered hover>
                                    <thead>
                                    <th>Событие</th>
                                    <th>Время</th>
                                    </thead>
                                    <tbody>
                                    {data.events && data.events
                                        //.filter(item => item.message.indexOf('open task:') < 0)
                                        .map((item, index) => {
                                            return <tr>
                                                <td className={'col-8 text-start'}>{item.timestamp}</td>
                                                <td className={'col-4 text-start'}>{item.message}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Container>
                        </Container>
                    */}
                </Container>
            }</>
    );
}

export default MovesReport

import {Container} from "react-bootstrap";
import PaperView from "./PaperView";

const PointView = (params) => {
    const paper = params.paper;

    let markedFirst = null;
    let markedSecond = null;
    let markedThird = null;

    if (paper)
    {
        markedFirst = paper[0].isUsed;
        markedSecond = paper[1].isUsed;
        markedThird = paper[2].isUsed;
    }

    const width = params.isSmall ? '150': '';
    return (
        <Container>
            {markedFirst ? <PaperView label={'1'} paper={paper[0].points} width={width}/> : <img src={'/point_view/paper-transparent.png'} width={width} alt="paper1"/>}
            {markedSecond ? <PaperView label={'2'} paper={paper[1].points} width={width}/> : <img src={'/point_view/paper-transparent.png'} width={width} alt="paper2"/>}
            {markedThird ? <PaperView label={'3'} paper={paper[2].points} width={width}/> : <img src={'/point_view/paper-transparent.png'} width={width} alt="paper3"/>}
            <hr />
        </Container>
    );
}

export default PointView

import {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminBugs = () => {
    const [localProblems, setLocalProblems] = useState([]);

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/bug_report.php',
            {
                    get: 1
            }
        );
        console.log(response.data);
        setLocalProblems(response.data?.bugs);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container>
            <Container className={'mt-4'}>
                <h1>Баги</h1>
            </Container>
            <Container>
                <ol className={'text-start'}>
                    {localProblems.map((item, index) =>
                    {
                        return <li className={'my-4'} key={index}><strong>{item.user}</strong> {item.message}</li>
                    })
                    }
                </ol>
            </Container>
        </Container>
    );
}

export default AdminBugs

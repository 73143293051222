import {Accordion} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import AuthContext from "../../../context/AuthProvider";
import {SERVER_URL} from "../../../Config";
import log from "../../../Logger";

const Fenomen = () => {
    const { setTask, task } = useContext(AuthContext);
    const [fenomData, setFenomData] = useState([]);

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/fenomen.php',
            { params: { problem:task.id } }
        );
        setFenomData(response.data);
        log('Fenomen, loaded');
    }
    useEffect(() => {
        fetchFenom();
    }, []);

    return (
        <Accordion className={'mt-2'}>
            {
                fenomData.map((item, index) =>
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header onClick={(event) => log('Fenomen read (' + event.target.ariaExpanded + ',"' + item.title + '")' )}>{item.title}</Accordion.Header>
                        <Accordion.Body className={'text-justify'}>
                            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                        </Accordion.Body>
                    </Accordion.Item>
                )
            }
        </Accordion>
    );
}

export default Fenomen

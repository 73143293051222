import {Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../../context/AuthProvider";
import PointView from "./PointView";

const PointsHistory = (params) => {

    const { point } = useContext(AuthContext);
    return (
        <Container>
            {
                point.pointsHistory.map((item) =>
                {
                    return (<PointView paper={item} isSmall={true}></PointView>);
                })
            }
        </Container>
    );
}

export default PointsHistory

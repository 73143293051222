import BeginEndProcessor from "../base/BeginEndProcessor";

export default class Methods extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Данные психологического обследования';

        this.beginEvents = [
            'chapter&quot;:3,',
            'chapteru0022:3'
        ];
        this.endEvents = [
            'MainDiagnos, click back',
            'MainDiagnos, click main menu',
            'MainDiagnos, click apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];

        this.diagsReadCount = 0;
    }

    tryToApply(event)
    {
        super.tryToApply(event);

        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;

        if (this.contains(event, 'open task'))
        {
            return;
        }

        if (this.contains(event, 'Diag read (false,'))
        {
            this.diagsReadCount++;
        }
    }

    end(event)
    {
        this.events.push(
            {
                name: 'Методик прочитано',
                value: this.diagsReadCount
            }
        );
        this.diagsReadCount = 0;
        this.beginFormulationTime = null;
        this.totalFormulationTime = 0;
        this.startTime = null;
        this.entered = '';
        this.read = [];
        this.selected = [];
        this.methods = [];
    }

    changingProblem()
    {
        if (this.startTime || this.diagsReadCount > 0)
        {
            this.end();
        }
    }

}

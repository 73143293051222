import {Button, Container, InputGroup, Form, Table, Collapse, Dropdown, Alert} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminTeachers = () => {

    const [usersData, setUsersData] = useState([]);
    const [email, setEmail] = useState('');
    const [fio, setFio] = useState('');
    const [error, setError] = useState(null);

    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/teachers.php');
        setUsersData(response.data);
    }

    async function addNew() {
        const response = await axios.get(SERVER_URL + '/api/teachers.php?add=1&fio=' + fio + '&email=' + email);
        setUsersData(response.data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function onBlock(id) {
        const response = await axios.get(SERVER_URL + '/api/teachers.php', {
            params: { "block": id }
        });
        setUsersData(response.data);
    }
    async function onUnBlock(id) {
        const response = await axios.get(SERVER_URL + '/api/teachers.php', {
            params: { "unblock": id }
        });
        setUsersData(response.data);
    }

    return (
        <>
        <Table bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Email</th>
                <th>ФИО</th>
                <th>Логин/Пароль</th>
                <th>Управление</th>
            </tr>
            </thead>
            <tbody>
            { usersData.map(item =>
                <tr>
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>{item.fio}</td>
                    <td>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Посмотреть логин и пароль
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => navigator.clipboard.writeText(item.login)}>{item.login}</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => navigator.clipboard.writeText(item.password)}>{item.password}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td>
                        {item.blocked == 1 ?
                            <Button className={'btn-secondary'} onClick={() => onUnBlock(item.id)}>Разблокировать</Button>
                            :
                            <Button className={'btn-warning'} onClick={() => onBlock(item.id)}>Заблокировать</Button>
                        }
                    </td>
                </tr>
            ) }
            </tbody>
        </Table>
        <Container>
            <Form.Group className="mb-3">
                <Form.Label>Укажите емайл нового преподавателя:</Form.Label>
                <Form.Control
                    type="text"
                    id="email"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Укажите Ф.И.О. преподавателя:</Form.Label>
                <Form.Control
                    type="text"
                    id="fio"
                    autoComplete="off"
                    onChange={(e) => setFio(e.target.value)}
                    value={fio}
                    required
                />
            </Form.Group>
            <Button href={'#'} onClick={()=>{
                addNew();
            }}>Создать нового преподавателя</Button>
        </Container>
    </>
    );
}

export default AdminTeachers

import BeginEndProcessor from "../base/BeginEndProcessor";

export default class Fenomen extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);

        this.eventName = 'Феноменология';

        this.beginEvents = [
            'Fenomen, loaded'
        ];
        this.endEvents = [
            'Fenomen, click back',
            'Fenomen, click main menu',
            'FenomenResume, click apply',
            'View Instruction'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);

        const enteredMatch = event.message.match(/fenomeno:\s*\((.*)\)/s)
        if (enteredMatch && enteredMatch[1])
        {
            if (enteredMatch[1] !== 'enter to input field')
            {
                this.entered = enteredMatch[1]
                    .replaceAll('u0022', '"').replaceAll('u0027', "'")
                    .replaceAll('&quot;', '"');
            }
        }

        const readMatch = event.message.match(/Fenomen\sread\s\((false),(u0022|&quot;)(.*)(u0022|&quot;)\)/)
        if (readMatch && readMatch[2])
        {
            if (this.read.indexOf(readMatch[3]) < 0)
            {
                this.read.push(readMatch[3]);
            }
        }
    }
}

import Header from "@editorjs/header";
import Paragraph from "editorjs-paragraph-with-alignment";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Delimiter from "@editorjs/delimiter";
import NestedList from "@editorjs/nested-list";
import Underline from "@editorjs/underline";
import {Container} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import EditorJS from "@editorjs/editorjs";

const AdminViewEditor = (props) => {
    let editId = Math.floor(Math.random() * 0xFFFFF);

    const editorInstance = useRef();
    const createEditor = () =>
    {
        const editor = new EditorJS({
            holder: 'editorjs-' + editId,
            minHeight: 5,
            data: props.content,
            readOnly: true,
            onReady: () =>
            {
                editorInstance.current = editor;
            },
            tools: {
                header: Header,
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                },
                table: Table,
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: '/api/upload_image.php',
                            byUrl: '/api/upload_url.php'
                        }
                    }
                },
                delimiter: Delimiter,
                nestedList: NestedList,
                underline: Underline
            }
        });
    };
    useEffect(() => {
        if (!editorInstance.current) {
            createEditor();
        }
        return () => {
            editorInstance.current.destroy();
            editorInstance.current = null;
        }
    }, []);

    return  <Container className={'border bg-light'}><div id={'editorjs-'+editId}></div></Container>
}

export default AdminViewEditor


import {Button, Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import Image from "../Image";
import log from "../../Logger";

const PsyDeterm = () =>
{
    const { setTask, task } = useContext(AuthContext);


    return (
        <>
            <h1>Схема детерминации</h1>
            <Container className={'border'}>
                <Image src={'sd.png'}></Image>
            </Container>
            <Container className={'my-4'}>
                <Button className={'float-start'} onClick={() => {
                    log('Psy determ, click back');
                    setTask({...task, chapter: 10, number: 1});
                }}>Назад</Button>
                <Button className={'align-middle'} onClick={() => {
                    log('Psy determ, click Main menu');
                    setTask({...task, chapter: 10, number: 1})
                }}>Главное меню</Button>
                <Button className={'float-end'} onClick={() => {
                    log('Psy determ, click Apply');
                    setTask({...task, chapter: 10, number: 1})
                }}>Принять</Button>
            </Container>
        </>);
}
export default PsyDeterm

import CumulativeProcessor from "../base/CumulativeProcessor";

export default class Katamnez extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Катамнез';

        this.beginEvents = [
            'showed katamnez'
        ];
        this.endEvents = [
            'katamnez, click next problem',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }
}


import BeginEndProcessor from "../base/BeginEndProcessor";

export default class PsyProblem extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Психологическая проблема';

        this.beginEvents = [
            'chapter&quot;:8,',
            'chapteru0022:8,'
        ];
        this.endEvents = [
            'PsyProblem, click back',
            'PsyProblem, click main menu',
            'PsyProblem, click apply',
            'Hypos, click back',
            'Hypos, click main menu',
            'Hypos, click apply',
            'View Instruction'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);

        //Формулирование проблемы - 2 мин
        if (this.contains(event, 'construct (Психологическая проблема): (enter to input field)'))
        {
            this.beginFormulationTime = new Date(event.timestamp);
        }
        else
        {
            if (this.contains(event, 'construct (Психологическая проблема)'))
            {
                this.totalFormulationTime += new Date(event.timestamp) - this.beginFormulationTime;
                this.beginFormulationTime = null;

                //Сформулировал: ..............
                const enteredMatch = event.message.match(/construct\s\(Психологическая проблема\)\s*:\s*\((.*)\)/s)
                if (enteredMatch && enteredMatch[1])
                {
                    if (enteredMatch[1] !== 'enter to input field')
                    {
                        this.entered = enteredMatch[1].replaceAll('u0022', '"').replaceAll('u0027', "'");
                    }
                }
            }
        }

        //Уточнил: .....................
        const enteredMatch = event.message.match(/add: PsyProblem\((.*)\)/);
        if (enteredMatch && enteredMatch[1])
        {
            this.selected.push(enteredMatch[1]);
        }

        // X Уточнение проблемы - 1 мин
    }
}

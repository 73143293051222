import BaseProcessor from "./BaseProcessor";

export default class BeginEndProcessor extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.startTime = null;

        this.beginFormulationTime = null;
        this.totalFormulationTime = 0;

        this.eventName = 'No Name';
        this.beginEvents = [];
        this.endEvents = [];

        this.entered = '';
        this.read = [];
        this.selected = [];
        this.methods = [];
    }

    tryToApply(event)
    {
        if (this.beginEvents.reduce((value, currentValue) => value || this.contains(event, currentValue), false ))
        {
            this.begin(event);
        }
        if (this.startTime)
        {
            if (this.endEvents.reduce((value, currentValue) => value || this.contains(event, currentValue), false ))
            {
                this.end(event);
            }
        }
    }

    begin(event)
    {
        if (!this.startTime)
        {
            this.startTime = new Date(event.timestamp);
        }
    }

    end(event)
    {
        this.events.push(
            {
                name: this.eventName,
                time: new Date(event.timestamp) - this.startTime,
                entered: this.entered,
                read: this.read,
                totalFormulationTime: this.totalFormulationTime,
                selected: this.selected,
                methods: this.methods
            }
        );
        this.beginFormulationTime = null;
        this.totalFormulationTime = 0;
        this.startTime = null;
        this.entered = '';
        this.read = [];
        this.selected = [];
        this.methods = [];
    }

    changingProblem()
    {
        this.beginFormulationTime = null;
        this.totalFormulationTime = 0;
        this.entered = '';
        this.read = [];
        this.selected = [];
        this.methods = [];
    }
}

export default class EventsKeeper
{
    constructor()
    {
        this.allEvents = []
        this.current = [];
        this.allEvents.push(this.current);
    }

    get()
    {
        return this.current;
    }

    push(item)
    {
        this.current.push(item)
    }

    inc()
    {
        this.current = [];
        this.allEvents.push(this.current);
    }

    map(func)
    {
        return this.current.map(func);
    }

}

import {SERVER_URL} from "./Config";
import axios from "axios";

const LOGGER_URL = SERVER_URL + '/api/logger.php';

let token = '';

const log = (message) => {
    const response = axios.post(LOGGER_URL,
        {
            token,
            message
        }
    );
    response.then((data) =>
    {
        if (data.data.expired_data == 1)
        {
            window.location.reload();
        };
    });
}

export const logFlush = () => {

}

export const setToken = (t) => token = t;

export default log;

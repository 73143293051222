import {Button, Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import PredMeropWrite from "./predMerop/PredMeropWrite";
import PredMeropSummary from "./predMerop/PredMeropSummary";
import log from "../../Logger";

const PredMerop = () => {
    const { setTask, task } = useContext(AuthContext);

    return (
        <>
            {
                task && task.number === 1 ?<>
                    <PredMeropWrite></PredMeropWrite>
                    <Container className={'my-4'}>
                        <Button className={'float-start'} onClick={() => {
                            log('PredMeropWrite, click back');
                            setTask({...task, chapter: 10, number: 1})
                        }}>Назад</Button>
                        <Button className={'align-middle'} onClick={() => {
                            log('PredMeropWrite, click Main menu');
                            setTask({...task, chapter: 10, number: 1})
                        }}>Главное меню</Button>
                        <Button className={'float-end'} onClick={() => {
                            log('PredMeropWrite, click Apply');
                            setTask({...task, chapter: 6, number: 2})
                        }}>Далее</Button>
                    </Container>
                </>: ''
            }
            {
                task && task.number === 2 ? <>
                    <PredMeropSummary></PredMeropSummary>
                    <Container className={'my-4'}>
                        <Button className={'float-start'} onClick={() => {
                            log('PredMeropSummary, click back');
                            setTask({...task, chapter: 6, number: 1})
                        }}>Назад</Button>
                        <Button className={'align-middle'} onClick={() => {
                            log('PredMeropSummary, click Main menu');
                            setTask({...task, chapter: 10, number: 1})
                        }}>Главное меню</Button>
                        <Button className={'float-end'} onClick={() => {
                            log('PredMeropSummary, click Apply');
                            setTask({...task, chapter: 10, number: 1})
                        }}>Принять</Button>
                    </Container>
                </> : ''
            }
        </>
    );
}

export default PredMerop

import BeginEndProcessor from "../base/BeginEndProcessor";

export default class Katamnez extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Катамнез';

        this.beginEvents = [
            'showed katamnez'
        ];
        this.endEvents = [
            'katamnez, click next problem',
            'View Instruction'
        ];
    }
}


import BeginEndProcessor from "../base/BeginEndProcessor";

export default class PredMerop extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Предложенные мероприятия';

        this.beginEvents = [
            'chapter&quot;:6,',
            'chapteru0022:6,'
        ];
        this.endEvents = [
            'PredMeropWrite, click back',
            'PredMeropWrite, click Main menu',
            'PredMeropSummary, click Main menu',
            'PredMeropSummary, click Apply',
            'View Instruction'
        ];
    }
}


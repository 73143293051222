import CumulativeProcessor from "../base/CumulativeProcessor";

export default class PredMerop extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Предложенные мероприятия';

        this.beginEvents = [
            'chapter&quot;:6,',
            'chapteru0022:6,'
        ];
        this.endEvents = [
            'PredMeropWrite, click back',
            'PredMeropWrite, click Main menu',
            'PredMeropSummary, click Main menu',
            'PredMeropSummary, click Apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }
}


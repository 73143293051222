import TotalTime from "./common/TotalTime";
import Problems from "./common/Problems";
import ResultSolve from "./common/ResultSolve";
import Fenomen from "./full/Fenomen";
import PsyProblem from "./full/PsyProblem";
import Hypos from "./full/Hypos";
import DiagMethods from "./full/DiagMethods";
import DiagSummary from "./full/DiagSummary";
import PsyDeterm from "./full/PsyDeterm";
import PredMerop from "./full/PredMerop";
import Katamnez from "./full/Katamnez";
import EventsKeeper from "./base/EventsKeeper";

export default class AnalyticsFull
{
    constructor()
    {
        this.events = new EventsKeeper();
        this.problems = new Problems(this.events, () => this.beforeChangeProblem());

        this.processors = [
            new Fenomen(this.events),
            new PsyProblem(this.events),
            new Hypos(this.events),
            new DiagMethods(this.events),
            new DiagSummary(this.events),
            new ResultSolve(this.events),
            new TotalTime(this.events),
            new PsyDeterm(this.events),
            new PredMerop(this.events),
            new Katamnez(this.events),
            //new Pause(this.events),
            this.problems
        ];
    }

    beforeChangeProblem()
    {
        this.processors.forEach(event => {
            if (event.changingProblem)
            {
                event.changingProblem();
            }
        });
    }

    fetch(data)
    {
        for (let event of data.events)
        {
            for (let processor of this.processors)
            {
                processor.tryToApply(event);
            }
        }
        this.beforeChangeProblem();
    }

    getProblems()
    {
        return this.problems.get();
    }
}

import CumulativeProcessor from "../base/CumulativeProcessor";

export default class DiagMethods extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Данные психологического обследования';

        this.beginEvents = [
            'chapter&quot;:3,',
            'chapteru0022:3'
        ];
        this.endEvents = [
            'MainDiagnos, click back',
            'MainDiagnos, click main menu',
            'MainDiagnos, click apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];


        this.prevEvent = null;
    }
    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }
}

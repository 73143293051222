import {Button, Container, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminSettings = (props) => {

    const [timeToSkip, setTimeToSkip] = new useState(null);
    const [wrongAnswersToSkip, setWrongAnswersToSkip] = new useState(30);

    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/settings.php');
        setTimeToSkip(Math.round(response.data.timeToSkip / 60000));
        setWrongAnswersToSkip(Math.round(response.data.wrongAnswersToSkip));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container>
            <Container className={'text-start'}>Время до разрешения пропуска задачи в минутах</Container>
            <Form.Control type={'number'} onChange={(e) => setTimeToSkip(e.target.value)} value={timeToSkip}></Form.Control>
            <Container className={'text-start'}>Колличество попыток решения задачи</Container>
            <Form.Control type={'number'} onChange={(e) => setWrongAnswersToSkip(e.target.value)} value={wrongAnswersToSkip}></Form.Control>
            <Button href={'#'} onClick={()=>{
                axios.get(SERVER_URL + '/api/settings.php', {
                    params: {
                        timeToSkip: timeToSkip * 60000,
                        wrongAnswersToSkip
                    }
                });
                if (props.onSave)
                {
                    props.onSave();
                }
            }}>Сохранить</Button>
        </Container>
    );
}

export default AdminSettings

import {useEffect, useState} from "react";
import {Container, Table} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminWishes = () => {
    const [localProblems, setLocalProblems] = useState([]);

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/wishes.php',
            {
                get: 1
            }
        );
        setLocalProblems(response.data?.wishes);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container>
            <Container className={'mt-4'}>
                <h1>Пожелания</h1>
            </Container>
            <Container>
                <Table bordered hover className={'text-start'}>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Группа</th>
                        <th>ФИО</th>
                        <th>Опишите свое состояние после решения</th>
                        <th>Что понравилось?</th>
                        <th>Что не понравилось?</th>
                        <th>Что вызвало затруднения?</th>
                        <th>Что бы Вы улучшили в программе?</th>
                        <th>Какие способы решения задач вы использовали?</th>
                    </tr>
                    </thead>
                    <tbody>
                    {localProblems && localProblems.map((item, index) =>
                    {
                        return <tr className={'my-4'}>
                            <td>{item.date}</td>
                            <td>{item.inviteName}</td>
                            <td>{item.fio}</td>
                            <td>{item.wish1}</td>
                            <td>{item.wish2}</td>
                            <td>{item.wish3}</td>
                            <td>{item.wish4}</td>
                            <td>{item.wish5}</td>
                            <td>{item.wish6}</td>
                        </tr>
                    })
                    }
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
}

export default AdminWishes

import {useContext, useState} from "react";
import AdminProblems from "./admin/AdminProblems";
import {Button, Container} from "react-bootstrap";
import AdminInvite from "./admin/AdminInvite";
import AdminUsers from "./admin/AdminUsers";
import AuthContext from "../context/AuthProvider";
import AdminHypos from "./admin/AdminHypos";
import AdminProblemsConst from "./admin/AdminProblemsConst";

const AdminSub = () => {
    const { editId } = useContext(AuthContext);

    const [adminPage, setAdminPage] = useState(0);

    return (
        <>
            {adminPage != 0 && !editId && <Button href={'#'} onClick={()=>{setAdminPage(0);}}>Назад</Button>}
            {adminPage === 0 &&
                <Container className={'text-start'}>
                    <ul>
                        <li className={'my-4'} key="2"><a href={'#'} onClick={()=>{setAdminPage(2);}}>Приглашения</a></li>
                        <li className={'my-4'} key="3"><a href={'#'} onClick={()=>{setAdminPage(3);}}>Пользователи</a></li>
                    </ul>
                </Container>
            }
            {adminPage === 2 && <AdminInvite sub={true}></AdminInvite>}
            {adminPage === 3 && <AdminUsers sub={true}></AdminUsers>}
        </>
    );
}

export default AdminSub

import BaseProcessor from "./BaseProcessor";

export default class CumulativeProcessor extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.startTime = null;
        this.totalTime = 0;

        this.eventName = 'No Name';
        this.beginEvents = [];
        this.endEvents = [];
    }

    tryToApply(event)
    {
        if (this.beginEvents.reduce((value, currentValue) => value || this.contains(event, currentValue), false ))
        {
            this.begin(event);
        }
        if (this.startTime)
        {
            if (this.endEvents.reduce((value, currentValue) => value || this.contains(event, currentValue), false ))
            {
                this.end(event);
            }
        }
    }

    begin(event)
    {
        if (!this.startTime)
        {
            this.startTime = new Date(event.timestamp);
        }
    }

    end(event)
    {
        this.totalTime += new Date(event.timestamp) - this.startTime;
        this.startTime = null;
    }

    changingProblem()
    {
        this.events.push(
            {
                name: this.eventName,
                time: this.totalTime
            }
        );
        this.totalTime = 0;
    }
}

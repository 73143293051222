import BaseProcessor from "../base/BaseProcessor";

export default class ResultSolve extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.right = false;
        this.counterWrong = 0
        this.totalFormulationTime = 0;
    }

    tryToApply(event)
    {
        if (this.contains(event, 'open task'))
        {
            return;
        }

        if (this.contains(event, 'fullRight'))
        {
            this.right = true;
        }
        if (this.contains(event, 'separateRight'))
        {
            this.counterWrong++;
        }
        if (this.contains(event, 'noAnswer'))
        {
            this.counterWrong++;
        }
    }

    changingProblem()
    {
        this.events.push(
            {
                name: 'Кол-во диагнозов',
                value: this.counterWrong + 1
            }
        );

        this.events.push(
            {
                name: 'Задача решена верно',
                value: this.right? '1': '0'
            }
        );

        this.counterWrong = 0;
        this.right = false;
    }
}

import BaseProcessor from "../base/BaseProcessor";

export default class ViewInstruction extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.startTime = null;
        this.prevEvent = null;
    }

    tryToApply(event)
    {
        if (
            this.contains(event, 'View Instruction') ||
            this.contains(event, 'Click to Instructions')
        )
        {
            this.begin(event);
        }
        if (
            (
                this.contains(event, 'chapteru0022:0') ||
                this.contains(event, 'chapter&quot;:0') ||
                this.contains(event, 'window closed') ||
                this.contains(event, 'invite_expired')
            )&&
            this.startTime
        )
        {
            this.end(event);
        }
        if (this.contains(event, 'Login') && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }

    begin(event)
    {
        if (!this.startTime)
        {
            this.startTime = new Date(event.timestamp);
        }
    }

    end(event)
    {
        this.events.push(
            {
                name: 'Чтение инструкции',
                time: new Date(event.timestamp) - this.startTime
            }
        );
        this.startTime = null;
    }
}

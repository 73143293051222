import { useContext } from 'react';
import AuthContext from "../context/AuthProvider";
import {Button, Container} from "react-bootstrap";

const ShowRegisterComplete = () => {
    const { auth, setRegistrationComplete } = useContext(AuthContext);
    return (
        <Container>
            <Container className={'text-center'}><h1>Регистрация завершена.</h1></Container>
            <Container><h4>Ваш логин: <h3 className={'text-success'}>{auth.user}</h3></h4></Container>
            <Container><h4>Ваш пароль: <h3 className={'text-success'}>{auth.pwd}</h3></h4></Container>
            <Container><h4 className={'text-danger'}>Пожалуйста запишите для последующего входа в систему</h4></Container>
            <Container className={'text-center mt-5'}><Button onClick={() => window.location.reload()}>Да я записал и готов приступить к работе</Button></Container>

            <Container className={'mt-5'}>Письмо с логином и паролем послано на указанную почту. Если оно не появилось во входящих, обязательно проверьте папку спам!</Container>
        </Container>
    )
}

export default ShowRegisterComplete

import BeginEndProcessor from "../base/BeginEndProcessor";

export default class Fenomen extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);

        this.eventName = 'Феноменология';

        this.beginEvents = [
            'Fenomen, loaded'
        ];
        this.endEvents = [
            'Fenomen, click back',
            'Fenomen, click main menu',
            'FenomenResume, click apply',
            'View Instruction'
        ];
    }
}

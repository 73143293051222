import {Button, Container} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import {SERVER_URL} from "../../../Config";
import log from "../../../Logger";
import PsyProblemWrite from "./PsyProblemWrite";

const PsyProblem = () => {
    const { setTask, task } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [hyposData, setHyposData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/problem_const.php',
            {  }
        );
        setHyposData(response.data.map(item => item.title));
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container className={'row'}>

            <Container className={'col-lg-4'}>
                <h5 className={'mt-3'}>
                    {task && task.number === 1 ? 'Заметки по феноменологии':'' }
                </h5>
                <Container className={'background-grey py-2 text-start'}>
                    <div dangerouslySetInnerHTML={{__html: task.description}}></div>
                </Container>
            </Container>

            <Container className={'col-lg-8 mb-4'}>
                {
                    task && task.number === 1 ?<>
                        {loaded && <PsyProblemWrite
                            titleName={'Психологическая проблема'}
                            descText={'Уточните психологическую проблему для её опознания программой'}
                            text={'Психологическая проблема'}
                            placeholder={'Введите поочередно первые три буквы психологической проблемы'}
                            value={task.freePsyProblem}
                            data={hyposData}></PsyProblemWrite>}
                        <Container className={'my-4'}>
                            <Button className={'float-start'} onClick={() => {
                                log('PsyProblem, click back');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Назад</Button>
                            <Button className={'align-middle'} onClick={() => {
                                log('PsyProblem, click main menu');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Главное меню</Button>
                            <Button className={'float-end'} onClick={() => {
                                log('PsyProblem, click apply');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Принять</Button>
                        </Container>
                    </>: ''
                }
            </Container>
        </Container>
    );
}

export default PsyProblem

import TotalTime from "./common/TotalTime";
import Problems from "./common/Problems";
import EventsKeeper from "./base/EventsKeeper";
import Fenomen from "./short/Fenomen";
import Hypos from "./short/Hypos";
import DiagMethods from "./short/DiagMethods";
import DiagSummary from "./short/DiagSummary";
import PredMerop from "./short/PredMerop";
import Katamnez from "./short/Katamnez";
import HyposMax from "./short/HyposMax";
import HyposSelected from "./short/HyposSelected";
import DiagMax from "./short/DiagMax";
import DiagRead from "./short/DiagRead";
import ResultSolve from "./common/ResultSolve";

export default class AnalyticsIntegral
{
    constructor()
    {
        this.events = new EventsKeeper();
        this.problems = new Problems(this.events, () => this.beforeChangeProblem());

        this.processors = [
            new TotalTime(this.events),
            new Fenomen(this.events),
            new Hypos(this.events),
            new DiagMethods(this.events),
            new DiagSummary(this.events),
            new PredMerop(this.events),
            new Katamnez(this.events),
            new HyposSelected(this.events),
            new HyposMax(this.events),
            new DiagRead(this.events),
            new DiagMax(this.events),
            new ResultSolve(this.events),
            //new Pause(this.events),
            this.problems
        ];
    }

    beforeChangeProblem()
    {
        this.processors.forEach(event => {
            if (event.changingProblem)
            {
                event.changingProblem();
            }
        });
    }

    fetch(data)
    {
        for (let event of data.events)
        {
            for (let processor of this.processors)
            {
                processor.tryToApply(event);
            }
        }
        this.beforeChangeProblem();
    }

    getProblems()
    {
        return this.problems.get();
    }
}

import BeginEndProcessor from "../base/BeginEndProcessor";

export default class DiagSummary extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Диагностическое заключение';

        this.beginEvents = [
            'chapter&quot;:4,',
            'chapteru0022:4,'
        ];
        this.endEvents = [
            'DiagSummary, click back',
            'DiagSummary, click main menu',
            'DiagSummary2, main menu',
            'DiagSummary2, apply',
            'chapter&quot;:0,',
            'chapteru0022:0,',
            'FenomenResume, click main menu',
            'FenomenResume, main menu',
            'FenomenResume, apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];
        this.prevEvent = null;
    }

    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }

        //Написание гипотез - 2 мин
        if (this.contains(event, 'DiagSummaryWrite: (enter to input field)'))
        {
            this.beginFormulationTime = new Date(event.timestamp);
        } else
        {
            if (this.contains(event, 'DiagSummaryWrite: ('))
            {
                this.totalFormulationTime += new Date(event.timestamp) - this.beginFormulationTime;
                this.beginFormulationTime = null;

                //Сформулировал: ..............
                const enteredMatch = event.message.match(/DiagSummaryWrite:\s*\((.*)\)/s)
                if (enteredMatch && enteredMatch[1])
                {
                    if (enteredMatch[1] !== 'enter to input field')
                    {
                        this.entered = enteredMatch[1].replaceAll('u0022', '"').replaceAll('u0027', "'");
                    }
                }
            }
        }

        const enteredMatch = event.message.match(/add: diagCheck\((.*)\)/);
        if (enteredMatch && enteredMatch[1])
        {
            this.selected.push(enteredMatch[1]);
        }
        this.prevEvent = event;
    }

}

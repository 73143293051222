import {Container} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../../Config";
import AuthContext from "../../../context/AuthProvider";
import log from "../../../Logger";
import DynamicText from "../../DynamicText";

const PredMeropSummary = () => {
    const { setTask, task } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [predMerop, setPredMerop] = useState('');
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/problem.php',
            { params: { problem:task.id } }
        );
        setData(response.data);
        setPredMerop(response.data.pred_merop);
        log('PredMeropSummary, loaded');
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <Container>
                <h3 className={'mt-3'}>Предложенные мероприятия</h3>
                <Container className={'border'}>
                    <DynamicText json={predMerop}></DynamicText>
                </Container>
            </Container>
        </>
    );
}

export default PredMeropSummary

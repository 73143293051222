import {Container} from "react-bootstrap";
import Image from "./Image";
import AdminViewEditor from "./admin/AdminViewEditor";

const DynamicText = (props) =>
{
    return (
        <>
            {props && props.json && <>
                {props.json.version ?
                    <AdminViewEditor content={props.json}></AdminViewEditor>
                    :
                    <>
                        {props.json.map((item) => {
                            if (item.type == 'text') {
                                return <Container><div dangerouslySetInnerHTML={{ __html: item.content.replaceAll('&quot;', '"')}}></div></Container>
                            }
                            if (item.type == 'image') {
                                return <Image src={item.content}></Image>
                            }
                            if (item.type == 'iframe') {
                                return <Container><iframe width="100%" height="700px" src={item.content}></iframe></Container>
                            }
                            return '';
                        })}
                    </>
                }
            </>}
        </>);
}
export default DynamicText

import Form from "react-bootstrap/Form";
import {Accordion, Container} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import {SERVER_URL} from "../../../Config";
import DynamicText from "../../DynamicText";
import log, {logFlush} from "../../../Logger";
import PointsHistory from "../point/PointsHistory";

const Diagnos = () => {
    const { setTask, task } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [visibleUpButton, setVisibleUpButton] = useState(false);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/diagnos.php',
            { params: { problem:task.id } }
        );
        setData(response.data);
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleMessageChange = event => {
        setTask({...task, mainDiagnos: event.target.value})
    };

    const handleFocusIn = event => {
        log('diag: (enter to input field)');
    }

    const handleFocusOut = event => {
        log('diag: (' + event.target.value  + ' )');
    }

    useEffect(() => {
        const handleScroll = event => {
            setVisibleUpButton(window.scrollY > 400);
        };
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let tempName = null;
    return (
        <>
            <Container>
                        <h2>Проверка гипотез</h2>
            </Container>
            
            <Container className={'mt-2'}>
                {task.hyposCheck &&
                    <Accordion className={'mt-2'}>
                        <Accordion.Item className={'acc-highlighted'} eventKey={'1'}>
                            <Accordion.Header onClick={(event) => log('DiagHypos (' + event.target.ariaExpanded + ',"Гипотезы")' )}>{'Гипотезы'}</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <ul className={'background-grey py-2'}>
                                        {task.hyposCheck.split('\n').map(item => <li className={'text-start'}>{item}</li>)}
                                    </ul>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }

                <Container className={'row'}>
                    
                {
                    task && task.number === 2 ?<>
                        <Container className={'col-5'}>
                            <iframe src={'/point/?mode=0'} width={'100%'} height={'860px'} />
                        </Container>
                    </>: ''
                }
                {
                    task && task.number === 3 ?<>
                        <Container className={'col-5'}>
                            <iframe src={'/point/?mode=1'} width={'100%'} height={'860px'} />
                        </Container>
                        <Container className={'col-7'}>
                        <h5>Шаг 4</h5>
                        {/*<Container>Гипотезы</Container>
                        <Container>
                            Гипотеза 1:
                            <Form.Control
                                type="text"
                                id="pointHypo"
                                placeholder='Введите гипотезу'
                            />
                        </Container>*/}
                        <Container>
                            <Container>Результаты обработки (проставления точек)</Container>
                            <PointsHistory></PointsHistory>
                        </Container>
                    </Container>
                    </>: ''
                }                          
                   
                   
                    
                    <Container>
                        <h5 id="top" className={'mt-3'}>
                            В этом окне Вы можете записать выводы по результатам проверки гипотез
                        </h5>

                        <Form.Control as="textarea" rows={3} onChange={handleMessageChange} value={task.mainDiagnos} onFocus={handleFocusIn} onBlur={handleFocusOut}/>
                    </Container>
                </Container>

                <Container>

                    {/* <h3 className={'mt-3'}>Данные психологического обследования</h3> */}

                     {/* {loaded &&
                         <Accordion className={'mt-2'}>
                             {
                                 data.map((d, index) =>
                                 {
                                     return (
                                         <Accordion.Item eventKey={index.toString()}>
                                             <Accordion.Header className={'highlightedBlue'}  onClick={(event) => log('DiagSec read (' + event.target.ariaExpanded + ', "' + d.title + '")' )}>{d.title}</Accordion.Header>
                                             <Accordion.Body>

                                                 <Accordion>
                                                     {
                                                         d.content.map((sub, index) =>
                                                         {
                                                             return (
                                                                 <Accordion.Item eventKey={index.toString()}>
                                                                     <Accordion.Header onClick={(event) => log('Diag read (' + event.target.ariaExpanded + ',"' + sub.title + '")' )}>{sub.title}</Accordion.Header>
                                                                     <Accordion.Body className={'text-justify'}>
                                                                         <DynamicText json={sub.content}></DynamicText>
                                                                     </Accordion.Body>
                                                                 </Accordion.Item>)
                                                         })
                                                     }
                                                 </Accordion>

                                             </Accordion.Body>
                                         </Accordion.Item>)
                                 })
                             }
                         </Accordion>
                     } */}
                </Container>
            </Container>
            <div id={"up-button"} className={visibleUpButton?'up-button' :'up-button-hided'}><a href={'#top'}>Наверх</a></div>
        </>
    );
}

export default Diagnos;

export default class BaseProcessor
{
    contains (event, substring)
    {
        return (event.message.indexOf(substring) >= 0);
    }

    constructor(events)
    {
        this.events = events;
    }

}

import BaseProcessor from "../base/BaseProcessor";

export default class DiagMax extends BaseProcessor
{

    constructor(events)
    {
        super(events);
        this.max = 0
    }

    tryToApply(event)
    {
        if (this.contains(event, 'open task'))
        {
            const hyposMatch = event.message.replaceAll('\n', '\\n').match(/diagCheck(u0022|&quot;):(u0022|&quot;)([^u0022]+)(u0022|&quot;)/);
            if (hyposMatch && hyposMatch[3])
            {
                const hypos = hyposMatch[3].split('\\n');
                if (hypos.length > this.max)
                {
                    this.max = hypos.length;
                }
            }
        }
    }

    changingProblem()
    {
        this.events.push(
            {
                name: 'Максимальное кол-во причин в одном диагнозе',
                value: this.max
            }
        );

        this.max = 0;
    }
}


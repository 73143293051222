import {Button, Container} from "react-bootstrap";
import log from "../Logger";

const Thanks = () => {
    log('finish lesson');
    return (
        <Container className={'mt-5 row'}>
            <Container>
                <div className={'row my-4'}>
                    <h1>Спасибо Вам большое! Вы решили все задачи.</h1>
                </div>
            </Container>

            <Container className={'mt-1'}>
                <Button onClick={()=>{
                    log('finish lesson click logout');
                    window.location.reload()
                }}>Выйти</Button>
            </Container>
        </Container>
    );
}

export default Thanks

import {Container, Form} from "react-bootstrap";
import {useState} from "react";

const CheckAndInput = (props) => {
    const [visible, setVisible] = useState(false);

    return <Container>
        <Form.Check
            type='checkbox'
            id={props.id}
            label={props.label}
            onChange={(e) => setVisible(e.target.checked)}
        />
        { visible &&
            <Form.Control
                type="text"
                id={'input_' + props.id}
                autoComplete="off"
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
            />
        }
    </Container>;
}

export default CheckAndInput;

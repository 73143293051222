import Form from "react-bootstrap/Form";
import {Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../../context/AuthProvider";
import log from "../../../Logger";

const PredMeropWrite = () => {
    const { setTask, task } = useContext(AuthContext);

    const handleMessageChangeOne = event => {
        setTask({...task, perdMetodOne: event.target.value})
    };
    const handleMessageChangeTwo = event => {
        setTask({...task, perdMetodTwo: event.target.value})
    };

    const handleFocusIn = event => {
        log('predMetod1: (enter to input field)');
    }

    const handleFocusOut = event => {
        log('predMetod1: (' + event.target.value  + ' )');
    }

    const handleFocusIn2 = event => {
        log('predMetod2: (enter to input field)');
    }

    const handleFocusOut2 = event => {
        log('predMetod2: (' + event.target.value  + ' )');
    }

    return (
        <>
            <h1>Предложенные мероприятия</h1>

            <Container>
                <h3 className={'mt-3'}>Матери</h3>
                <Form.Control as="textarea" rows={3} onChange={handleMessageChangeOne} value={task.perdMetodOne} onFocus={handleFocusIn} onBlur={handleFocusOut}/>
            </Container>

            <Container>
                <h3 className={'mt-3'}>Подростку</h3>
                <Form.Control as="textarea" rows={3} onChange={handleMessageChangeTwo} value={task.perdMetodTwo} onFocus={handleFocusIn2} onBlur={handleFocusOut2}/>
            </Container>
        </>
    );
}

export default PredMeropWrite

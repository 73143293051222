import BaseProcessor from "../base/BaseProcessor";

export default class DiagRead extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.methods = [];
        this.diagsReadCount = 0
        this.methodName = null;
        this.startTime = null;
    }

    tryToApply(event)
    {
        if (this.contains(event, 'open task'))
        {
            return;
        }

        if (this.contains(event, 'Diag read (false'))
        {
            if (this.startTime)
            {
                const time =new Date(event.timestamp) - this.startTime;
                this.methods.push([this.methodName, time]);
            }

            this.startTime = new Date(event.timestamp);
            this.diagsReadCount++;
            const numberProblem = event.message.match(/\((.*,(u0022|&quot;)(.*)(u0022|&quot;))\)/)
            if (numberProblem && numberProblem[3])
            {
                this.methodName = numberProblem[3];
            }
        }
        if (this.startTime && (
            this.contains(event, 'Diag read (true') ||
            this.contains(event,'MainDiagnos, click back')||
            this.contains(event,'MainDiagnos, click main menu')||
            this.contains(event,'MainDiagnos, click apply')||
            this.contains(event,'View Instruction')||
            this.contains(event,'window closed')||
            this.contains(event,'invite_expired')
        ))
        {
            const time =new Date(event.timestamp) - this.startTime;
            this.methods.push([this.methodName, time]);
            this.startTime = null;
        }
    }

    changingProblem()
    {
        this.methods.forEach(method => {
            this.events.push(
                {
                    name: method[0],
                    time: method[1]
                }
            );
        })

        this.events.push(
            {
                name: 'Методик запрошено',
                value: this.diagsReadCount
            }
        );

        this.diagsReadCount = 0;
        this.methods = [];
    }
}

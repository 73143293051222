import {Button, Container, Form, Modal} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {useEffect, useRef, useState} from "react";
import {ReactSortable} from "react-sortablejs";

const AdminEditTaskOrder = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [order, setOrderData] = useState(null);
    const [state, setState] = useState([]);

    const [show, setShow] = useState(false);

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/invites_order.php',
            {
                id: props.inv
            }
        );
        setOrderData(response.data);

        const keys = Object.keys(response.data.order);
        const initState = [];
        if (keys.length == 0)
        {
            setState(response.data.problems);
        }
        else
        {
            keys.forEach(key => initState[key-1] = response.data.problems[response.data.order[key]-1]);
            setState(initState);
        }
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const inputEl = useRef(null);

    async function save()
    {
        const order = [];
        state.forEach((item, index) => order.push([index + 1, item.id]))
        const response = await axios.post(SERVER_URL + '/api/invites_order.php',
            {
                id: props.inv,
                order
            }
        );
        inputEl.current.className = 'btn btn-success';

        setTimeout(()=>{
            inputEl.current.className = 'btn btn-primary';
        }, 1000);
    }

    async function saveDefault()
    {
        const order = [];
        state.forEach((item, index) => order.push([index + 1, item.id]))
        const response = await axios.post(SERVER_URL + '/api/invites_order_default.php',
            {
                id: props.inv,
                order
            }
        );
        setShow(false)
    }

    return (
        <Container>
            {order &&
                <>
                    <Container>
                        Рудактируем очередь для - <b>{order.name}</b>
                    </Container>
                    <Container>
                        <>начало работы ссылки</>
                        &nbsp;
                        {
                            new Date(order.start).getDate() + '-' + (new Date(order.start).getMonth() + 1) + '-' + new Date(order.start).getFullYear()
                            + ' ' +
                            new Date(order.start).getHours() + ':' + new Date(order.start).getMinutes()
                        }
                    </Container>

                    <Container>
                        <>конец работы ссылки</>
                        &nbsp;
                        {
                            new Date(order.end).getDate() + '-' + (new Date(order.end).getMonth() + 1) + '-' + new Date(order.end).getFullYear()
                            + ' ' +
                            new Date(order.end).getHours() + ':' + new Date(order.end).getMinutes()
                        }
                    </Container>

                    <Container>
                        <ReactSortable
                            group="groupName"
                            animation={200}
                            delayOnTouchStart={true}
                            delay={2}

                            tag="ol" list={state} setList={setState}>
                            {state.map((item) => (
                                <li className={'order-item text-start'} key={item.id}>{item.id} - {item.title}</li>
                            ))}
                        </ReactSortable>
                    </Container>

                    <Container className={'text-start'}>
                        <a href={'#'} onClick={()=>setShow(true)}>Сохранить(по умолчанию)</a>
                    </Container>
                    <Container className={'text-end'}>
                        <Button ref={inputEl} onClick={()=>save()}>Сохранить</Button>
                    </Container>
                </>
            }

            <Modal
                show={show}
                onHide={() => {setShow(false);}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Назначить очередность по умолчанию</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Внимание! Для всех новых приглашений будет применяться дання очередность.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={saveDefault}>Применить</Button>
                    <Button variant="secondary" onClick={() => {setShow(false);}}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default AdminEditTaskOrder

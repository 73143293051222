import {Container} from "react-bootstrap";

const PaperView = (params) => {


    const paper = params.paper;

    /*

    <span>
    {
        paper.blue.marked &&
            <div><img src={'/point_view/point-blue.png'}/></div>
    }
    {
        paper.green.marked &&
            <div><img src={'/point_view/point-green.png'}/></div>
    }
    {
        paper.red.marked &&
            <div><img src={'/point_view/point-red.png'}/></div>
    }
    </span>
 */
    console.log(paper);

    function calculateDeviation (pointState)
    {

        const switcher = pointState.switcher;
        const frame = pointState.frame;
        const table = pointState.table;

        const deviation = switcher + frame + table;
        if (deviation < -1 || deviation > 1)
        {
            return null;
        }
        console.log(deviation);
        return deviation;
    }

    const blueDeviation = calculateDeviation(paper.blue);
    const greenDeviation = calculateDeviation(paper.green);
    const redDeviation = calculateDeviation(paper.red);

    return (
        <span className="paper-container">
            <span className="paper-content">
                <img src="/point_view/paper.png" alt="paper" width="180"/>
            </span>
            <span className="paper-content-left">
                <div className="paper-content-point">
                    {redDeviation === -1 ?
                        <img src="/point_view/point-red.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point paper-content-point-center-left">
                    {greenDeviation === -1 ?
                        <img src="/point_view/point-green.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point paper-content-point-bottom-left">
                    {blueDeviation === -1 ?
                        <img src="/point_view/point-blue.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
            </span>
            <span className="paper-content-center">
                <div className="paper-content-point">
                    {redDeviation === 0 ?
                        <img src="/point_view/point-red.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point">
                    {greenDeviation === 0 ?
                        <img src="/point_view/point-green.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point">
                    {blueDeviation === 0 ?
                        <img src="/point_view/point-blue.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point paper-content-label">{params.label}</div>
            </span>
            <span className="paper-content-right">
                <div className="paper-content-point">
                    {redDeviation === 1 ?
                        <img src="/point_view/point-red.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point paper-content-point-center-right">
                    {greenDeviation === 1 ?
                        <img src="/point_view/point-green.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
                <div className="paper-content-point paper-content-point-bottom-right">
                    {blueDeviation === 1 ?
                        <img src="/point_view/point-blue.png" />
                        :
                        <img src="/point_view/point-transparent.png" />
                    }
                </div>
            </span>
        </span>
    );
}

export default PaperView

import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Container, Table} from "react-bootstrap";
import AnalyticsShort from "../../analytics/AnalyticsShort";

const GroupAverageReport = (props) => {
    const [preparedCount, setPreparedCount] = useState(1);

    const [data, setData] = useState([]);

    const [problems, setProblems] = useState([]);

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }
        return Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)';
    }

    let countData = 0;
    const allReports = [];
    async function fetchData(id) {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:undefined, user_id: id } }
        );
        const anlyticsData = new AnalyticsShort();
        anlyticsData.fetch(response.data);
        allReports.push(anlyticsData.getProblems());

        countData++;
        if (props.group.length > countData)
        {
            setTimeout(() =>
            {
                setPreparedCount(old => old + 1);
                fetchData(props.group[countData].id);
            }, 100);
        } else {
            setData(allReports);

            const totalData = [];
            allReports.forEach((report, index )=> {
                const average = {};
                report.forEach(problem => {
                    problem.events.forEach(event => {
                        if (!average[event.name])
                        {
                            average[event.name] = { name:event.name, time: 0, value: 0, count: 0 };
                        }
                        if (event.time)
                        {
                            average[event.name]['time'] = average[event.name]['time'] + parseFloat(event.time);
                        }
                        if (event.value)
                        {
                            average[event.name]['value'] = average[event.name]['value'] + parseFloat(event.value);
                        }
                        average[event.name]['count'] ++;
                    });

                });
                totalData.push({name: props.group[index].fio, keys:Object.keys(average), average});
            });
            setProblems(totalData);
        }
    }
    useEffect(() => {
        setTimeout(() =>
        {
            fetchData(props.group[countData].id);
        }, 100);
    }, []);

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протоколов {preparedCount}/{props.group.length}... </h2></Container> :
                <Container>
                    <Table bordered hover>
                        <tbody>
                        <tr><td className={'col-8 text-start'}>Среднее</td>
                            <td>fio</td>
                            {problems[0].keys.map(value => <td>{value}</td>)}
                        </tr>

                        {problems.length > 0 && problems.map(prob =>
                        {
                        return<>
                            <tr>
                                <td></td>
                                <td>{prob.name}</td>
                                {prob.keys.map(key => <td>
                                    {
                                        prob.average[key]['time'] != 0 && (Math.round((prob.average[key]['time'] /  prob.average[key]['count']) / 6000) / 10).toString().replaceAll('.',',')
                                    }
                                    {
                                        prob.average[key]['value'] != 0 && (prob.average[key]['value'] /  prob.average[key]['count']).toString().replaceAll('.',',')
                                    }
                                    </td>
                                )}
                            </tr>
                        </>
                        })
                    }
                        </tbody>
                    </Table>
                </Container>
            }</>
    );
}

export default GroupAverageReport

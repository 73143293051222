import {Accordion, Button, Container} from "react-bootstrap";
import Fenomen from "./fenomen/Fenomen";
import Form from "react-bootstrap/Form";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import FenomenResume from "./fenomen/FenomenResume";
import FenomenWrite from "./fenomen/FenomenWrite";
import log from "../../Logger";

const Fenomenologia = () => {
    const { setTask, task } = useContext(AuthContext);

    const handleMessageChange = event => {
        setTask({...task, description: event.target.value})
    };

    const handleFocusIn = event => {
        log('fenomeno: (enter to input field)');
    }

    const handleFocusOut = event => {
        log('fenomeno: (' + event.target.value  + ' )');
    }

    return (
        <>
            <Container className={'row'}>
                <Container>
                    <h2>Феноменология</h2>
                </Container>

                <Container className={'col-xl-4'}>
                    <Container className={'sticky-top'}>
                        <h5>
                            {task && task.number === 1 ? 'Заметки':'' }
                            {task && task.number === 2 ? 'Заметки':'' }
                            {task && task.number === 3 ? 'Резюме':'' }
                            {task && task.number === 4 ? 'Резюме по феноменологии':'' }
                        </h5>

                        { task && task.number === 1 && <Form.Control as="textarea" rows={5} onChange={handleMessageChange} onFocus={handleFocusIn} onBlur={handleFocusOut} value={task.description} placeholder={'Здесь Вы можете записать свои наблюдения'} /> }
                        { task && task.number === 2 && <Form.Control as="textarea" rows={5} disabled={'disabled'} value={task.description} /> }
                        { task && task.number === 3 && <Form.Control as="textarea" rows={5} disabled value={task.resumeFenom} /> }
                    </Container>
                </Container>

                <Container className={'col-xl-8'}>
                    {
                        task && task.number === 1 ?<>
                            <Fenomen></Fenomen>
                            <Container className={'my-4'}>
                                <Button className={'float-start'} onClick={() =>{
                                    log('Fenomen, click back');
                                    setTask({...task, chapter: 0, number: 1})
                                }}>Назад</Button>
                                <Button className={'align-middle'} onClick={() => {
                                    log('Fenomen, click main menu');
                                    setTask({...task, chapter: 0, number: 1})
                                }}>Главное меню</Button>
                                <Button className={'float-end'} onClick={() => {
                                    log('FenomenResume, click apply');
                                    setTask({...task, chapter: 0, number: 1})
                                }}>Принять</Button>
                            </Container>
                        </>: ''
                    }
                </Container>
            </Container>
        </>
    );
}

export default Fenomenologia

import {Button, Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "editorjs-paragraph-with-alignment";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Delimiter from "@editorjs/delimiter";
import NestedList from "@editorjs/nested-list";
import Underline from "@editorjs/underline";
import DynamicText from "../DynamicText";

const AdminEditObsledMethod = (props) => {
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        setEditor(new EditorJS({
            holder: 'editorjs2',
            minHeight : 5,
            data: props.content,
            tools: {
                header: Header,
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                },
                table: Table,
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: '/api/upload_image.php',
                            byUrl:  '/api/upload_url.php'
                        }
                    }
                },
                delimiter: Delimiter,
                nestedList: NestedList,
                underline: Underline
            }
        }));
    }, []);

    return (
        <Container>
            {props.content && !props.content.version && <>
                <Container><h4>Старое Содержимое</h4></Container>
                <Container><DynamicText json={props.content}></DynamicText></Container>
            </>}
            <Container><h4>Новое Содержимое</h4></Container>
            <Container className={'border bg-light'}><div id={'editorjs2'}></div></Container>
            <Container>
                <Button href={'#'} onClick={()=>{
                    editor.save().then((outputData) => {
                        props.onSave(outputData);
                    });
                }}>Сохранить</Button>
            </Container>
        </Container>
    );
}

export default AdminEditObsledMethod

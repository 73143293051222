import {useState} from "react";
import log from "../Logger";

const Image = (props) =>
{
    const [zoom, setZoom] = useState(false);

    function clickHandle() {
        setZoom(!zoom);
        log('Clicked on image zoom (zoom, ' + props.src + ')');
    }

    return (
        <>
            <img className={'image'} src={props.src} onClick={clickHandle}></img>
            { zoom && <img className={'zoomed'} src={props.src} onClick={clickHandle}></img> }
        </>);
}
export default Image

import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Container, Table} from "react-bootstrap";
import AnalyticsShort from "../../analytics/AnalyticsShort";

const GroupAverageByProblem = (props) => {
    const [preparedCount, setPreparedCount] = useState(1);

    const [data, setData] = useState([]);

    const [problems, setProblems] = useState([]);
    const [keys, setKeys] = useState([]);

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }
        return Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)';
    }

    let countData = 0;
    const allReports = [];
    async function fetchData(id) {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:undefined, user_id: id } }
        );
        const anlyticsData = new AnalyticsShort();
        anlyticsData.fetch(response.data);
        allReports.push(anlyticsData.getProblems());

        countData++;
        if (props.group.length > countData)
        {
            setTimeout(() =>
            {
                setPreparedCount(old => old + 1);
                fetchData(props.group[countData].id);
            }, 100);
        } else {
            setData(allReports);

            const averages = {};
            allReports.forEach((report, index )=>
            {
                report.forEach(problem => {
                    if (!averages[problem.page]) {
                        averages[problem.page] = {};
                    }
                    const average = averages[parseInt(problem.page)];

                    problem.events.forEach(event => {
                        if (!average[event.name])
                        {
                            average[event.name] = { name:event.name, time: 0, value: 0, count: 0 };
                        }
                        if (event.time)
                        {
                            average[event.name]['time'] = average[event.name]['time'] + Math.round(parseFloat(event.time) / 6000) / 10 ;
                        }
                        if (event.value)
                        {
                            average[event.name]['value'] = average[event.name]['value'] + parseFloat(event.value);
                        }
                        average[event.name]['count'] ++;
                    });
                });
            });
            setProblems(averages);
            setKeys(Object.keys(averages));
        }
    }
    useEffect(() => {
        setTimeout(() =>
        {
            fetchData(props.group[countData].id);
        }, 100);
    }, []);

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протоколов {preparedCount}/{props.group.length}... </h2></Container> :
                <Container>
                    <Table bordered hover>
                        <tbody>
                        <tr><td className={'col-8 text-start'}>Задача</td>
                            {Object.keys(problems[keys[0]]).map(value => <td>{value}</td>)}
                        </tr>

                        {keys.length > 0 && keys.map(key =>
                        {
                            return<>
                                <tr>
                                    <td>{key}</td>
                                    {Object.keys(problems[key]).map(probKey => <td>
                                        {
                                                problems[key][probKey]['time'] != 0 && (problems[key][probKey]['time'] / problems[key][probKey]['count']).toString().replaceAll('.',',')
                                            }
                                            {
                                                problems[key][probKey]['value'] != 0 && (problems[key][probKey]['value'] /  problems[key][probKey]['count']).toString().replaceAll('.',',')
                                            }
                                        </td>
                                    )}
                                </tr>
                            </>
                        })
                        }
                        </tbody>
                    </Table>
                </Container>
            }</>
    );
}

export default GroupAverageByProblem

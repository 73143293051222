import EventsKeeper from "./base/EventsKeeper";
import TotalTime from "./common/TotalTime";
import Login from "./common/Login";
import Problems from "./common/Problems";
import ResultSolve from "./common/ResultSolve";
import WindowClose from "./common/WindowClose";
import ViewInstruction from "./common/ViewInstruction";
import Fenomen from "./moves/Fenomen";
import PsyProblem from "./moves/PsyProblem";
import Hypos from "./moves/Hypos";
import DiagMethods from "./moves/DiagMethods";
import DiagSummary from "./moves/DiagSummary";
import PsyDeterm from "./moves/PsyDeterm";
import PredMerop from "./moves/PredMerop";
import Katamnez from "./moves/Katamnez";
import ChapterSelect from "./moves/ChapterSelect";

export default class AnalyticsMoves
{
    constructor()
    {
        this.events = new EventsKeeper();
        this.problems = new Problems(this.events, () => this.beforeChangeProblem());

        this.processors = [
            new ViewInstruction(this.events),
            new Fenomen(this.events),
            new PsyProblem(this.events),
            new Hypos(this.events),
            new DiagMethods(this.events),
            new DiagSummary(this.events),
            new PsyDeterm(this.events),
            new PredMerop(this.events),
            new Katamnez(this.events),
            //new Pause(this.events),
            this.problems
        ];
    }

    beforeChangeProblem()
    {
        this.processors.forEach(event => {
            if (event.changingProblem)
            {
                event.changingProblem();
            }
        });
    }

    fetch(data)
    {
        for (let event of data.events)
        {
            for (let processor of this.processors)
            {
                processor.tryToApply(event);
            }
        }
        this.beforeChangeProblem();
    }

    getProblems()
    {
        return this.problems.get();
    }
}

import CumulativeProcessor from "../base/CumulativeProcessor";

export default class ViewInstruction extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);

        this.eventName = 'Чтение инструкции';

        this.beginEvents = [
            'View Instruction',
            'Click to Instructions'
        ];
        this.endEvents = [
            'chapteru0022:0',
            'chapter&quot;:0',
            'window closed',
            'invite_expired'
        ];

        this.prevEvent = null;
    }
    tryToApply(event)
    {
        super.tryToApply(event);
        if (this.contains(event, 'Login') && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }

}

import {useContext, useEffect, useState} from "react";
import {Button, Container, Modal} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import AuthContext from "../../context/AuthProvider";
import AdminEdit from "./AdminEdit";

const AdminProblems = () => {
    const { editId, setEditId } = useContext(AuthContext);

    const [localProblems, setLocalProblems] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [show, setShow] = useState(false);
    const [isAddMode, setAddMode] = useState(false);

    const [titleForDelete, setTitleForDelete] = useState(null);
    const [idForDelete, setIdForDelete] = useState(null);

    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/tasks.php',
            { }
        );
        setLocalProblems(response.data?.problems);
        setLoaded(true);
    }

    const refresh = async () => {
        const response = await axios.get(SERVER_URL + '/api/tasks.php',
            { }
        );
        setLocalProblems(response.data?.problems);
        window.scrollTo(0,0);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (id) => {
        setEditId(id);
    }

    const handleSetAddModeOn = () => {
        window.scrollTo(0,0,);
        setEditId(-1);
    }
    const clickDeleteProblem = (id, title) => {
        setIdForDelete(id);
        setTitleForDelete(title);
        setShow(true);
    }

    const deleteProblem = async () =>
    {
        await axios.get(SERVER_URL + '/api/delete_problem.php',
            { params: { id4delete: idForDelete } }
        );

        const response2 = await axios.get(SERVER_URL + '/api/tasks.php',
            { }
        );
        setLocalProblems(response2.data?.problems);

        setIdForDelete(null);
        setShow(false);
    }

    return (
        <>
            {editId &&
                <AdminEdit id={editId} onDone={refresh}/>
            }
            {!editId && !isAddMode && loaded &&
                <Container>
                    <Container className={'mt-4'}>
                        <h1>Задачи</h1>
                    </Container>
                        <Container>
                            <ol className={'text-start'}>
                                {localProblems.map((item, index) =>
                                    {
                                        return <li className={'my-4'} key={index}><a href={'#'} onClick={() =>
                                        {
                                            handleEdit(item.id);
                                        }}>{item.title}</a>
                                            &nbsp; <a href={'#'}  className={'text-danger'} onClick={() => clickDeleteProblem(item.id, item.title)}>Удалить</a></li>
                                    })
                                }
                            </ol>
                        </Container>
                    <Container>
                        <Button className={'mb-4'} onClick={handleSetAddModeOn}>
                            Добавить новую
                        </Button>
                    </Container>
                </Container>
            }
            {!editId && isAddMode &&
                <AdminEdit id={editId} onDone={refresh}/>
            }
            <Modal
                show={show}
                onHide={() => {setShow(false); setIdForDelete(null);}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Удалить задачу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{titleForDelete}</h5>
                    Внимание! После удаления, задачу нельзя будет восстановить. Вы точно хотите это сделать?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteProblem}>Удалить</Button>
                    <Button variant="secondary" onClick={() => {setShow(false); setIdForDelete(null);}}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AdminProblems

import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "./context/AuthProvider";

import {Accordion, Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "./Config";
import log, {setToken} from "./Logger";
import CheckAndInput from "./components/CheckAndInput";
const REGISTRATION_URL = SERVER_URL + '/api/registration.php';

const Registration = () => {
    const { setAuth, setProblems, setRegistrationComplete } = useContext(AuthContext);
    const [error, setError] = useState(null);

    const [email, setEmail] = useState('');
    const [fio, setFio] = useState('');
    const [kurs, setKurs] = useState('');
    const [group, setGroup] = useState('');
    const [level, setLevel] = useState('');
    const [form, setForm] = useState('');
    const [workplace, setWorkplace] = useState('');

    const [expPsy, setExpPsy] = useState('');
    const [expPed, setExpPed] = useState('');
    const [expMed, setExpMed] = useState('');
    const [expTech, setExpTech] = useState('');
    const [expOther, setExpOther] = useState('');
    const [disable, setDisable] = useState(false);

    const [errorUsedEmail, setErrorUsedEmail] = useState(false);

    const [age, setAge] = useState('');

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setDisable(true);

        if (!isValidEmail(email)) {
            window.scrollTo(0, 0);
            setError('Email is invalid');
            setDisable(false);
            return;
        } else {
            setError(null);
        }

        const inv = window.location.href.match(/([?])(.+)/ );
        const invite = inv[2];

        const response = await axios.post(REGISTRATION_URL,
        {
                email,
                fio,
                form,
                level,
                kurs,
                group,
                workplace,
                expPsy,
                expPed,
                expMed,
                expTech,
                expOther,
                age,
                invite
            }
        );

        if (response?.data?.errorUsedEmail)
        {
            window.scrollTo(0, 0);
            setErrorUsedEmail(true);
            setDisable(false);
            return;
        }

        const accessToken = response?.data?.accessToken;

        log('registred: (' + accessToken + ', ' + JSON.stringify({
            email,
            fio,
            form,
            level,
            kurs,
            group,
            workplace,
            expPsy,
            expPed,
            expMed,
            expTech,
            expOther,
            age,
            invite
        })  + ' )');

        const status = response?.data?.status;
        setAuth({ user:  response?.data?.login, pwd: response?.data?.password, accessToken, status });
        setToken(accessToken);

        const problems = response?.data?.problems;
        setProblems(problems);

        setRegistrationComplete(true);
        window.history.pushState({}, '', '/');
    }

    return (
        <section>
            <Form>
                <Alert variant="danger" className={error ? "errmsg" : "d-none"}>Введите верный Email</Alert>
                <Alert variant="danger" className={errorUsedEmail ? "errmsg" : "d-none"}>Такой e-mail уже используется.</Alert>

                <Form.Group className="mb-3">
                    <Form.Label>Ваша электронная почта:</Form.Label>
                    <h4 className={'text-danger'}>Пожалуйста, указывайте реальный емайл на него будет выслан пароль!</h4>
                    <Form.Control
                        type="text"
                        id="email"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Напишите ваше имя Ф.И.О.:</Form.Label>
                    <Form.Control
                        type="text"
                        id="fio"
                        autoComplete="off"
                        onChange={(e) => setFio(e.target.value)}
                        value={fio}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Укажите Ваш возраст</Form.Label>
                    <Form.Control
                        type="text"
                        id="age"
                        autoComplete="off"
                        onChange={(e) => setAge(e.target.value)}
                        value={age}
                        required
                    />
                </Form.Group>

                <Accordion className={'mt-2'}>
                    <Accordion.Item className={'acc-highlighted'} eventKey={'1'}>
                        <Accordion.Header>Уровень, форма, курс обучения, номер группы</Accordion.Header>
                        <Accordion.Body>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label>Уровень обучения</Form.Label>
                                <Form.Check
                                    name="group2"
                                    type='radio'
                                    label='Магистратура'
                                    id={`radio-4`}
                                    value='mag'
                                    onChange={(e) => setLevel(e.target.value)}
                                />
                                <Form.Check
                                    name="group2"
                                    type='radio'
                                    label='Бакалавриат'
                                    id={`radio-5`}
                                    value='bak'
                                    onChange={(e) => setLevel(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label>Форма обучения</Form.Label>
                                <Form.Check
                                    name="group1"
                                    type='radio'
                                    label='Очная'
                                    id={`radio-1`}
                                    value='ochn'
                                    onChange={(e) => setForm(e.target.value)}
                                />
                                <Form.Check
                                    name="group1"
                                    type='radio'
                                    label='Очно-заочная'
                                    id={`radio-2`}
                                    value='ochn-zaoch'
                                    onChange={(e) => setForm(e.target.value)}
                                />
                                <Form.Check
                                    name="group1"
                                    type='radio'
                                    label='Заочная'
                                    id={`radio-3`}
                                    value='zaoch'
                                    onChange={(e) => setForm(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Курс обучения</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="kurs"
                                    autoComplete="off"
                                    onChange={(e) => setGroup(e.target.value)}
                                    value={group}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Номер группы</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="kurs"
                                    autoComplete="off"
                                    onChange={(e) => setKurs(e.target.value)}
                                    value={kurs}
                                    required
                                />
                            </Form.Group>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <Form.Group className="mb-3">
                    <Form.Label>Место работы</Form.Label>
                    <Form.Control
                        type="text"
                        id="workplace"
                        autoComplete="off"
                        onChange={(e) => setWorkplace(e.target.value)}
                        value={workplace}
                        required
                    />
                </Form.Group>

                <Accordion className={'my-2'}>
                    <Accordion.Item className={'acc-highlighted'} eventKey={'1'}>
                        <Accordion.Header>Есть ли у вас опыт решения диагностических задач в</Accordion.Header>
                        <Accordion.Body>
                            <Form.Group className="mb-3 text-start">
                                <Container>
                                    <CheckAndInput
                                        id={`check-1`}
                                        label={`психологии`}
                                        placeholder={'Укажите подробнее (cтаж и т.п.)'}
                                        value={expPsy}
                                        onChange={(e) => setExpPsy(e.target.value)}
                                    >
                                    </CheckAndInput>

                                    <CheckAndInput
                                        id={`check-2`}
                                        label={`педогогике`}
                                        placeholder={'Укажите подробнее (cтаж и т.п.)'}
                                        value={expPed}
                                        onChange={(e) => setExpPed(e.target.value)}
                                    >
                                    </CheckAndInput>

                                    <CheckAndInput
                                        id={`check-3`}
                                        label={`медицине`}
                                        placeholder={'Укажите подробнее (cтаж и т.п.)'}
                                        value={expMed}
                                        onChange={(e) => setExpMed(e.target.value)}
                                    >
                                    </CheckAndInput>

                                    <CheckAndInput
                                        id={`check-4`}
                                        label={`техника`}
                                        placeholder={'Укажите подробнее (cтаж и т.п.)'}
                                        value={expTech}
                                        onChange={(e) => setExpTech(e.target.value)}
                                    >
                                    </CheckAndInput>

                                    <CheckAndInput
                                        id={`check-5`}
                                        label={`другое`}
                                        value={expOther}
                                        onChange={(e) => setExpOther(e.target.value)}
                                        placeholder={'Введите ваш вариант'}>
                                    </CheckAndInput>

                                </Container>
                            </Form.Group>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Container><Button disabled={disable} onClick={handleSubmit}>Зарегистрироваться</Button></Container>
                <Container><small>Нажимая «Зарегистрироваться», вы подтверждаете, что ознакомились с <a href={'agreement.html'} target={'_blank'}>соглашением на обработку данных</a> и согласились предоставить указанные выше данные на обработку.</small></Container>
            </Form>
        </section>
    )
}

export default Registration

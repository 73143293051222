import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Container, Table} from "react-bootstrap";
import AnalyticsShort from "../../analytics/AnalyticsShort";

const GroupReport = (props) => {
    const [preparedCount, setPreparedCount] = useState(1);

    const [origData, setOrigData] = useState([]);
    const [data, setData] = useState([]);

    const [problems, setProblems] = useState([]);

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }
        return Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)';
    }

    let countData = 0;
    const allReports = [];
    async function fetchData(id) {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:undefined, user_id: id } }
        );
        const anlyticsData = new AnalyticsShort();
        setOrigData(response.data);
        anlyticsData.fetch(response.data);
        allReports.push(anlyticsData.getProblems());

        countData++;
        if (props.group.length > countData)
        {
            setTimeout(() =>
            {
                setPreparedCount(old => old + 1);
                fetchData(props.group[countData].id);
            }, 100);
        } else {
            setData(allReports);

            const totalData = [];
            allReports.forEach((report, index )=> {
                report.forEach(problem => {
                    if (!totalData[problem.page])
                    {
                        totalData[problem.page] = [];
                    }
                    totalData[problem.page].push({name: props.group[index].fio, page:problem.page, problem});
                });
            });
            setProblems(totalData);
        }
    }
    useEffect(() => {
        setTimeout(() =>
        {
            fetchData(props.group[countData].id);
        }, 100);
    }, []);

    function convertToTaskName(page)
    {
        const task = origData.invite_order.find(item => item.problem_id == page);
        return 'Задача ' + page + '(' + task.id + ')'+ ' ' + task.title;
    }

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протоколов {preparedCount}/{props.group.length}... </h2></Container> :
                <Container>
                    <Table bordered hover>
                        <tbody>
                    {problems.length > 0 && problems.map(prob =>
                        {
                        return<>
                            <tr><td>&nbsp;</td></tr>
                            <tr><td className={'col-8 text-start'}>{convertToTaskName(prob[0].page)}</td>
                                <td>fio</td>
                                {prob[0].problem.events.map(event => <td>{event.name}</td>)}
                            </tr>
                            {prob.map(currentProb =><tr>
                                <td></td>
                                <td>{currentProb.name}</td>
                                {currentProb.problem.events.map(item =><td>
                                    {
                                        item.time && (Math.round(item.time/6000)/10).toString().replaceAll('.',',')
                                    }
                                    {
                                        item.value && item.value
                                    }
                                </td>)}
                            </tr>)}
                        </>
                        })
                    }
                        </tbody>
                    </Table>
                </Container>
            }</>
    );
}

export default GroupReport

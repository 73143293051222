import axios from "axios";
import {SERVER_URL} from "../../Config";
import {useContext, useEffect, useState} from "react";
import {Button, Container, Form} from "react-bootstrap";
import AuthContext from "../../context/AuthProvider";

const AdminHypos = () => {
    const { editId, setEditId } = useContext(AuthContext);

    const [title, setTitle] = useState('');
    const [problemData, setProblemData] = useState([]);

    async function fetchData() {
        const responseProblem = await axios.get(SERVER_URL + '/api/hypos_const.php');
        setProblemData(responseProblem.data);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const addConst = (value) => {
        if (!editId)
        {
            problemData.unshift(value);
            setProblemData([...problemData]);
            setTitle('');
        }
        else
        {
            problemData[editId] = value;
            setProblemData([...problemData]);
            setEditId(null);
            setTitle('');
        }
    }

    return (
        <Container>

            <Form.Group className="mb-3">
                <Form.Label>Заголовок</Form.Label>
                <Form.Control as="textarea" rows={2} id={'ad_title'} onChange={(e) => setTitle(e.target.value)} value={title}/>
            </Form.Group>
            <Container>
                <Button onClick={() => addConst({title})}>{!editId ? "Добавить": "Сохранить"}</Button>
            </Container>

            <Container>
                <ul>
                    {   problemData.map((item, index) =>
                        {
                            return <li>
                                {item.title}
                                &nbsp;
                                <a href={'javascript:void(0);'} onClick={()=>{
                                    setTitle(item.title);
                                    setEditId(index);
                                }}>Редактировать</a>
                                &nbsp;
                                <a href={'javascript:void(0);'} className={'text-danger'} onClick={()=>{
                                    problemData.splice(index, 1);
                                    setProblemData([...problemData]);
                                }}>Удалить</a>
                            </li>
                        }
                    )
                    }
                </ul>
            </Container>
        </Container>
    );
}

export default AdminHypos

import BeginEndProcessor from "../base/BeginEndProcessor";

export default class DiagSummary extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Диагностическое заключение';

        this.beginEvents = [
            'chapter&quot;:4,',
            'chapteru0022:4,'
        ];
        this.endEvents = [
            'DiagSummary, click back',
            'DiagSummary, click main menu',
            'DiagSummary2, main menu',
            'DiagSummary2, apply',
            'chapter&quot;:0,',
            'chapteru0022:0,',
            'FenomenResume, click main menu',
            'FenomenResume, main menu',
            'FenomenResume, apply',
            'View Instruction'
        ];
    }
}

import BaseProcessor from "../base/BaseProcessor";

export default class HyposSelected extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.diagsReadCount = 0
    }

    tryToApply(event)
    {
        if (this.contains(event, 'open task'))
        {
            return;
        }

        if (this.contains(event, 'add: undefined'))
        {
            this.diagsReadCount++;
        }
    }

    changingProblem()
    {
        this.events.push(
            {
                name: 'Гипотез запрошено',
                value: this.diagsReadCount
            }
        );

        this.diagsReadCount = 0;
        this.methods = [];
    }
}

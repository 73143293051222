import {Button, Container} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthProvider";
import Navigation from "../Navigation";
import Instruction from "../Instruction";
import ChapterSelect from "./ChapterSelect";
import Thanks from "./Thanks";
import axios from "axios";
import {SERVER_URL} from "../Config";

const CommercialPage = (props) => {
    const [trial, setTrial] = useState(false);
    const { auth, task, problems,  setProblems} = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [hyposData, setHyposData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/free_problems.php',
            { params: { intite:props.invite } }
        );

        const problems = response?.data?.problems;
        setProblems(problems);

        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {trial ?
                <>
                    <Navigation></Navigation>
                    <Container>
                        {
                            task && task.page === 0 ? <Instruction></Instruction> : ''
                        }
                        {
                            task && task.page > 0 && task.page <= problems.length + 1 ? <ChapterSelect></ChapterSelect> : ''
                        }
                        {
                            task && task.page > problems.length + 1 ? <Thanks></Thanks> : ''
                        }
                    </Container>
                </>
                :
                <Container>
                    <h1>Описание</h1>
                    <Container>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fermentum, leo eu efficitur
                        tincidunt, lectus nulla rutrum lorem, sit amet porttitor tortor ipsum efficitur ipsum. Aliquam
                        ligula mi, mattis ut placerat sit amet, consectetur ut libero. Praesent dui nulla, venenatis
                        scelerisque condimentum in, dapibus sit amet nisi. Aenean vel quam facilisis, mattis lectus vel,
                        sagittis nisi. Curabitur lacinia justo ut magna efficitur, in finibus ante bibendum. Vivamus
                        condimentum ipsum enim, quis ornare quam auctor ac. Nulla ut turpis feugiat, scelerisque sem
                        nec, auctor purus. Vivamus dignissim tempus lorem nec commodo. Praesent volutpat molestie est ut
                        fringilla. Aliquam faucibus consectetur condimentum. Donec efficitur dolor ligula, finibus
                        condimentum massa ornare a. Suspendisse potenti.</Container>
                    <Container><Button className={'btn-success'}>Купить за Х рублей</Button></Container>
                    <Container>In quis interdum erat. Donec et metus at elit molestie ullamcorper. Duis imperdiet
                        interdum nibh, eu sagittis turpis pulvinar quis. Ut cursus mauris sit amet orci fringilla
                        pellentesque. Nulla tincidunt suscipit nibh, vel rhoncus orci auctor in. Etiam non auctor nunc.
                        Phasellus ullamcorper lorem ac sem egestas, vitae vestibulum ligula pulvinar. Nulla ut lacus
                        commodo, imperdiet mauris nec, rutrum sapien. Nullam commodo, est auctor ultricies tempus, nibh
                        magna volutpat augue, at viverra risus risus vitae urna. Duis dignissim mattis mauris at
                        porttitor. Donec id dui lectus. Ut a commodo massa. Ut rutrum consequat iaculis. Etiam viverra
                        hendrerit lacus at consequat.</Container>
                    <Container><Button onClick={() => setTrial(true)}>Попробовать безплатно</Button></Container>
                    <Container>Morbi tincidunt leo neque, at imperdiet turpis efficitur vel. Proin semper ultrices
                        sagittis. Nullam fringilla, libero in venenatis varius, massa neque volutpat risus, vitae
                        ullamcorper ex lorem id nibh. Morbi vulputate lacus maximus, maximus leo id, fringilla turpis.
                        Nulla suscipit mi magna, et blandit dolor imperdiet et. Quisque malesuada diam sit amet tortor
                        dictum commodo. Nulla eros tortor, facilisis quis dignissim eu, consequat vitae tortor. Maecenas
                        imperdiet ligula faucibus nisl lobortis tempor. Nulla tristique gravida feugiat. Etiam ultrices
                        a mauris vitae ultricies. Sed interdum at lacus eget rutrum.</Container>
                    <Container><Button className={'btn-success'}>Купить за Х рублей</Button></Container>
                </Container>
            }
        </>
    );
}

export default CommercialPage

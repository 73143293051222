import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthProvider";
import {Button, Container} from "react-bootstrap";
import log from "../../Logger";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const FinalCheckDiagnos = () => {
    const { setTask, task } = useContext(AuthContext);

    const [finalResult, setFinalResult] = useState('noAnswer');

    let rightAnswer = [];
    const answers = task.diagCheck?task.diagCheck.split('\n'):[];
    const answersIDs = (answers.map(ans => {
        const full = task.diagFullAnswers.find(item => item.title === ans);
        if (full) {
            return full.id;
        }
        return null;
    }));

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/check.php',
            {
                id: task.id,
                answers: answersIDs
            }
        );
        setFinalResult(response.data.result);

        log(response.data.result);

        if (finalResult != 'fullRight') {
            task.wrongAnswers++
        }
        setTask({...task, openResult: response.data.result, wrongAnswers: task.wrongAnswers})
    }

    useEffect(() => {
        fetchData();
    },[]);

    return (
        <>
            { finalResult === 'fullRight' && <Container className={'text-success text-center'}>
                <h2 className={'my-4'}>Диагноз верен!</h2>
                <Container className={'mt-3'}>
                    &nbsp;
                </Container>
            </Container>}
            { finalResult === 'separateRight' && <Container className={'text-success text-center'}><h2>Диагноз частично верен!</h2></Container>}
            { finalResult === 'noAnswer' && <Container className={'text-danger text-center'}><h2>Диагноз неверен!</h2></Container>}
        </>
    );
}

export default FinalCheckDiagnos;

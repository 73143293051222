import {Button, Container} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthProvider";
import HypotheseWrite from "./hypos/HypotheseWrite";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import log from "../../Logger";

const Hypos = () => {
    const { setTask, task } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [hyposData, setHyposData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/hypos_const.php',
            { }
        );
        setHyposData(response.data.map(item => item.title));
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container className={'row'}>
            <Container>
                    <h2>Гипотезы</h2>
                </Container>

            <Container className={'col-lg-4'}>
                <h5 className={'mt-3'}>
                    {task && task.number === 1 ? 'Заметки по феноменологии':'' }
                </h5>
                {/* { task.psyProblemCheck &&
                    <ul className={'background-grey py-2'}>
                        {task.psyProblemCheck.split('\n').map(item=><li className={'text-start'}>{item}</li>)}
                    </ul>
                } */}
                <Container className={'background-grey py-2 text-start'}>
                    <div dangerouslySetInnerHTML={{__html: task.description}}></div>
                </Container>
            </Container>

            <Container className={'col-lg-8 mb-4'}>
                {
                    task && task.number === 1 ?<>
                    {
                        loaded && <HypotheseWrite
                            titleName={'Сформулируйте гипотезы'}
                            descText={'Уточните гипотезы для их опознания программой'}
                            text={'Уточненные гипотезы'}
                            placeholder={'Начните вводить краткое название элемента и направление смещения'}
                            value={task.hypos}
                            data={hyposData}
                            ></HypotheseWrite>
                    }

                        <Container className={'my-4'}>
                            <Button className={'float-start'} onClick={() => {
                                log('Hypos, click back');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Назад</Button>
                            <Button className={'align-middle'} onClick={() => {
                                log('Hypos, click main menu');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Главное меню</Button>
                            <Button className={'float-end'} onClick={() => {
                                log('Hypos, click apply');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Принять</Button>
                            
                        </Container>
                        <Container className={'my-4'}>
                            <Button className={'float-start'} onClick={() => {
                                log('Hypos, click apply');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Я уже знаю решение</Button>
                            <Button className={'float-end'} onClick={() => {
                                log('Hypos, click apply');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Я не считаю этот этап значимым</Button>
                        </Container>
                    </>: ''
                }
            </Container>
        </Container>
    );
}

export default Hypos

import {useEffect, useState} from "react";
import {Container, Table} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminSurvey = () => {
    const [localProblems, setLocalProblems] = useState([]);

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/survey.php',
            {
                get: 1
            }
        );
        setLocalProblems(response.data?.surveys);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
            <Container>
                <Container className={'mt-4'}>
                    <h1>Опрос</h1>
                </Container>
                <Container>
                    <Table bordered hover className={'text-start'}>
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Группа</th>
                            <th>ФИО</th>
                            <th>Номер задачи</th>
                            <th>Посильность поставленной перед собой задачи</th>
                            <th>Возможность сосредоточения</th>
                            <th>Четкая формулировка цели</th>
                            <th>Немедленная обратная связь</th>
                            <th>Высокая увлеченность, приводящая к забыванию повседневных тревог и забот</th>
                            <th>Ощущение контроля над своими действиями</th>
                            <th>Исчезновение своего Я в момент совершения действия</th>
                            <th>Изменение восприятия времени</th>
                        </tr>
                        </thead>
                        <tbody>
                        {localProblems && localProblems.map((item, index) =>
                            {
                                return <tr className={'my-4'}>
                                    <td>{item.data}</td>
                                    <td>{item.inviteName}</td>
                                    <td>{item.fio}</td>
                                    <td>{item.problem_id}</td>
                                    {item.survey && item.survey.map(sur => <td>{sur}</td>)}
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </Container>
            </Container>
    );
}

export default AdminSurvey

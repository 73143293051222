import {Button, Container, InputGroup, Form, Table, Collapse, Dropdown} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const AdminUsers = () => {

    const [loaded, setLoaded] = useState(false);
    const [dataGroupedByInvite, setDataGroupedByInvite] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [viewInvite, setViewInvite] = useState(null);

    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/users.php');
        setUsersData(response.data);

        response.data.forEach(user => {
           let group = dataGroupedByInvite[user.invite_id];
           if (group) {
               group.push(user);
           }
           else
           {
               dataGroupedByInvite[user.invite_id] = [];
               group = dataGroupedByInvite[user.invite_id];
               group.push(user);
           }
        });

        setDataGroupedByInvite(dataGroupedByInvite);
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    async function onBlock(id) {
        const response = await axios.get(SERVER_URL + '/api/users.php', {
            params: { "block": id }
        });
        setUsersData(response.data);
    }
    async function onUnBlock(id) {
        const response = await axios.get(SERVER_URL + '/api/users.php', {
            params: { "unblock": id }
        });
        setUsersData(response.data);
    }

    return (
        <>
            {viewInvite ?
                <>
                    {dataGroupedByInvite[viewInvite][0].invite === '' ?
                        <Container><h2>{'Без приглашения'}</h2></Container>
                    :
                        <Container><h2>{dataGroupedByInvite[viewInvite][0].invite}&nbsp;-&nbsp;{dataGroupedByInvite[viewInvite][0].invites_name === '' ? 'Без названия' : dataGroupedByInvite[viewInvite][0].invites_name}</h2></Container>
                    }
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>ФИО</th>
                                <th>Логин/Пароль</th>
                                <th>Управление</th>
                            </tr>
                        </thead>
                        <tbody>
                            { dataGroupedByInvite[viewInvite].map(item =>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.email}</td>
                                    <td>{item.fio}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Посмотреть логин и пароль
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={() => navigator.clipboard.writeText(item.login)}>{item.login}</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={() => navigator.clipboard.writeText(item.password)}>{item.password}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        {item.blocked == 1 ?
                                            <Button className={'btn-secondary'} onClick={() => onUnBlock(item.id)}>Разблокировать</Button>
                                            :
                                            <Button className={'btn-warning'} onClick={() => onBlock(item.id)}>Заблокировать</Button>
                                        }
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </Table>
                </>
                :
                <Container>
                    <h1>Пользователи по приглашениям</h1>
                    <Container>
                        {
                            dataGroupedByInvite.map((item, index) =>
                            {
                                return <Container className={'text-start'}>
                                    {item[0].invite === '' ?
                                        <a href={'#'}
                                           onClick={() => setViewInvite(item[0].invite_id)}>{'Без приглашения'}</a>
                                        :
                                        <a href={'#'}
                                           onClick={() => setViewInvite(item[0].invite_id)}>{item[0].invite}&nbsp;-&nbsp;{item[0].invites_name === '' ? 'Без названия' : item[0].invites_name}</a>
                                    }
                                </Container>;
                            })
                        }
                    </Container>
                </Container>
            }
        </>
    );
}

export default AdminUsers

import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "./context/AuthProvider";

import {Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {SERVER_URL} from "./Config";
import log, {setToken} from "./Logger";
const LOGIN_URL = SERVER_URL + '/api/auth.php';

const Login = () => {
    const { setAuth, setProblems, setTimeToSkip, setCommercial, setHasPaid, setWrongAnswersToSkip, setTask } = useContext(AuthContext);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(LOGIN_URL,
                { params: { user, pwd } }
            );

            if (response?.data?.error) {
                if (response.data.end_invite == 1) {
                    setErrMsg('Время действия пароля приостановлено');
                } else {
                    setErrMsg('Логин или пароль неверный. Попробуйте еще раз');
                }
                return;
            }

            const accessToken = response?.data?.accessToken;
            const status = response?.data?.status;
            const invite_end = response?.data?.invite_end;
            const invite_type = response?.data?.invite_type;
            const user_id = response?.data?.user_id;
            setAuth({ user, pwd, accessToken, status, invite_end, user_id, invite_type });
            setTimeToSkip(response?.data?.timeToSkip);
            setWrongAnswersToSkip(response?.data?.wrongAnswersToSkip);
            setCommercial(response?.data?.commercial != '0');
            setHasPaid(response?.data?.hasPaid != '0');
            setUser('');
            setPwd('');
            setSuccess(true);
            setToken(accessToken);

            const state = response?.data?.state;
            if (state) {
                setTask(state);
            }

            log('Login ' + user);

            const problems = response?.data?.problems;
            setProblems(problems);

            window.history.pushState({}, '', '/');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Отказано в доступе');
            }
        }
    }

    return (
        <>
            {success ? (
                ''
            ) : (
                <section>
                    <Alert variant="danger" className={errMsg ? "errmsg" : "d-none"}>{errMsg}</Alert>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Пользователь:</Form.Label>
                            <Form.Control
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Пароль:</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                        </Form.Group>
                        <Button onClick={handleSubmit}>Войти</Button>
                    </Form>
                </section>
            )}
            {errMsg && <Container><h1>{errMsg}</h1></Container> }
        </>
    )
}

export default Login

import CumulativeProcessor from "../base/CumulativeProcessor";

export default class PsyProblem extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Психологическая проблема';

        this.beginEvents = [
            'chapter&quot;:8,',
            'chapteru0022:8,'
        ];
        this.endEvents = [
            'PsyProblem, click back',
            'PsyProblem, click main menu',
            'PsyProblem, click apply',
            'Hypos, click back',
            'Hypos, click main menu',
            'Hypos, click apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }
}

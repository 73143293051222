import Form from "react-bootstrap/Form";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../context/AuthProvider";
import {Button, Container} from "react-bootstrap";
import log from "../../../Logger";

const HypotheseWrite = (props) => {
    const {task, setTask} = useContext(AuthContext);
    const [data, setData] = useState([...props.data]);
    const [selectedData, setSelectedData] = useState([]);
    const [filter, setFilter] = useState('');
    const [active, setActive] = useState(false);

    useEffect(() =>
    {
        if (props.whereSave === 'diagCheck')
        {
            if (task.diagCheck)
            {
                task.diagCheck.split('\n').forEach(selItem =>
                {
                    let newData = data.filter(item => item != selItem);
                    setData(newData);

                    if (selectedData.indexOf(selItem) === -1)
                    {
                        selectedData.push(selItem);
                        setSelectedData(selectedData);
                    }
                });
            }
        } else {
            if (task.hyposCheck)
            {
                task.hyposCheck.split('\n').forEach(selItem =>
                {
                    let newData = data.filter(item => item != selItem);
                    setData(newData);

                    if (selectedData.indexOf(selItem) === -1)
                    {
                        selectedData.push(selItem);
                        setSelectedData(selectedData);
                    }
                });
            }
        }
    }, []);

    const handleMessageChange = event => {
        setFilter(event.target.value);
    };

    const handleClick = event => {
        let value = event.currentTarget.getAttribute('userdata');
        let newData = data.filter(item => item != value);
        setData(newData);

        log('add: '+props.whereSave +'(' + value  + ' )');

        selectedData.push(value);
        setSelectedData(selectedData);

        if (props.whereSave === 'diagCheck') {
            setTask({...task, diagCheck: selectedData.join('\n')});
        } else {
            setTask({...task, hyposCheck: selectedData.join('\n')});
        }
    }

    const handleClickRemove = event => {
        let value = event.currentTarget.getAttribute('userdata');
        let newData = selectedData.filter(item => item != value);
        setSelectedData(newData);

        log('remove: '+props.whereSave +'(' + value  + ' )');

        data.unshift(value);
        setData(data);

        if (props.whereSave === 'diagCheck') {
            setTask({...task, diagCheck: newData.join('\n')});
        } else {
            setTask({...task, hyposCheck: newData.join('\n')});
        }
    }

    const handleMessageChange2 = event => {
        setActive(event.target.value.length > 4);
        setTask({...task, hypos: event.target.value})
    };

    const handleFocusIn = event => {
        log('construct (' + props.titleName + '): (enter to input field)');
    }

    const handleFocusOut = event => {
        log('construct (' + props.titleName + ') : (' + event.target.value  + ' )');
    }

    return (
        <>
            <h3 className={'mt-3'}>{props.titleName}</h3>
            {props.previewText?
                <Form.Control as="textarea" rows={3} onChange={handleMessageChange2} value={props.previewText} disabled={true}/>
                :
                <Form.Control as="textarea" rows={3} onChange={handleMessageChange2} value={props.value} onFocus={handleFocusIn} onBlur={handleFocusOut} disabled={props.alwaysActive}/>
            }
            <h4 className={'mt-3'} id="unselected">{props.descText}</h4>
            {selectedData.length > 0 &&
                <Container className={'border my-3 text-start bg-light'}>
                    <ul>
                        {
                            selectedData.map((item) =>
                            {
                                return (<Container onClick={handleClickRemove} userData={item}>
                                    <li>
                                        <div className={'hidenSVG d-flex'}>
                                            <a className={'me-auto'} href='#unselected'
                                               title={'Удалить в выбранное'}>{item}</a>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     fill="grey"
                                                     className="bi bi-x-circle m-2" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                </Container>);
                            })
                        }
                    </ul>
                </Container>
            }
            <Form.Control
                type="text"
                id="username"
                placeholder={props.placeholder}
                onChange={handleMessageChange}
                disabled={!props.alwaysActive && !active && !props.value || (props.value && props.value.length < 3)}
            />
             {/* <div className={'mt-2'}>
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                    <div class="row">
                        <div className={'col-4'}>
                            <Form.Label>Элемент:</Form.Label>
                            <Form.Control as="select"rows={5} onChange={(event) => {log('Hypos, click apply');}}>
                                <option value="Пк">Пластинка Красная</option>
                                <option value="Пс">Пластинка Синяя</option>
                                <option value="Пз">Пластинка Зеленая</option>
                                <option value="Р1">Рамка 1</option>
                                <option value="Р2">Рамка 2</option>
                                <option value="Р3">Рамка 3</option>
                                <option value="С">Стол</option>
                            </Form.Control>  
                        </div>
                        <div className={'col-4'}>
                            <Form.Label>Направление:</Form.Label>
                            <Form.Control as="select" rows={5}  onChange={(event) => {log('Hypos, click apply');}}>
                                <option value="по">По часовой</option>
                                <option value="пр">Против часовой</option>
                            </Form.Control>
                        </div>
                        <div className={'col-4'}>
                            <Form.Label>Момент обработки:</Form.Label>
                            <Form.Control as="select" rows={5}  onChange={(event) => {log('Hypos, click apply');}}>
                                <option value="1">Положение 1</option>
                                <option value="2">Положение 2</option>
                                <option value="3">Положение 3</option>
                            </Form.Control>
                        </div>
                    </div>    
                    </Form.Group>
                            <div class="mt-4">
                                <Button variant="primary"  onClick={() => {log('Diagnosys setted');}}>
                                    Добавить
                                </Button>
                            </div>
                    
                </Form>
            </div>  */}

            {data.filter((item) =>
                {
                    const excludes = [
                        'высокая',
                        'высокий',
                        'высокое',
                        'неадекватная',
                        'неадекватное',
                        'неадекватный',
                        'недостаточная',
                        'недостаточное',
                        'недостаточный',
                        'низкая',
                        'низкий',
                        'низкое',
                        'сильная',
                        'сильное',
                        'сильный',
                        'сниженная',
                        'сниженное',
                        'сниженный',
                        'стремление',
                        'уровень',
                        'уровни'
                    ];

                    if (!filter || filter === '' || filter.length < 3) return false;

                    let filterCleaned = filter;
                    /*
                    for (let excl of excludes) {
                        filterCleaned = filterCleaned.replaceAll(excl);
                    }
                     */
                    if (excludes.find(exl => exl.indexOf(filter.toLowerCase()) === 0)) return false;

                    return item.toLowerCase().indexOf(filterCleaned.toLowerCase()) >= 0
                }).length > 0  &&
                <Container className={'border my-3 text-start'}>
                    <ul>
                        {
                            data.filter((item) =>
                            {
                                const excludes = [
                                    'высокая',
                                    'высокий',
                                    'высокое',
                                    'неадекватная',
                                    'неадекватное',
                                    'неадекватный',
                                    'недостаточная',
                                    'недостаточное',
                                    'недостаточный',
                                    'низкая',
                                    'низкий',
                                    'низкое',
                                    'сильная',
                                    'сильное',
                                    'сильный',
                                    'сниженная',
                                    'сниженное',
                                    'сниженный',
                                    'стремление',
                                    'уровень',
                                    'уровни'
                                ];

                                if (!filter || filter === '' || filter.length < 3) return false;

                                let filterCleaned = filter;
                                /*
                                for (let excl of excludes) {
                                    filterCleaned = filterCleaned.replaceAll(excl);
                                }
                                 */
                                if (excludes.find(exl => exl.indexOf(filter.toLowerCase()) === 0)) return false;

                                return item.toLowerCase().indexOf(filterCleaned.toLowerCase()) >= 0
                            }).map((d, idx) =>
                            {
                                return (<Container onClick={handleClick} userData={d}>
                                    <li className={"hidenSVG"}>
                                        <a href='#selected' title={'Добавить в выбранное'}>{d}</a>
                                    </li>
                                </Container>)
                            })
                        }
                    </ul>
                </Container>
            }
        </>
    );
}

export default HypotheseWrite

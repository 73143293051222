import BeginEndProcessor from "../base/BeginEndProcessor";

export default class PsyDeterm extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Схема детерминации';

        this.beginEvents = [
            'chapter&quot;:5,',
            'chapteru0022:5,'
        ];
        this.endEvents = [
            'Psy determ, click back',
            'Psy determ, click Main menu',
            'Psy determ, click Apply',
            'View Instruction'
        ];
    }
}


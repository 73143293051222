import {Accordion, Button, Container, Form} from "react-bootstrap";
import {useState} from "react";
import DynamicText from "../DynamicText";
import AdminEditObsledMethod from "./AdminEditObsledMethod";

const AdminEditObsled = (props) => {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [editMethod, setEditMethod] = useState('');
    const [editTitleId, setEditTitleId] = useState(null);
    const [addNewTitle, setAddNewTitle] = useState(null);
    const [editObsledObject, setEditObsledObject] = useState(null);

    const [editingSection, setEditingSection] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const handleAddNewTitle = (id) => {
        setAddNewTitle(id);
    }

    const handleAddTitle = () => {
        const newObject = {
            new: 1,
            id: 'new-' + Math.round(Math.random() * 0x0fffffff),
            title: title,
            content: []
        };
        props.diagData.push(newObject);
        setTitle('');
        setAddNewTitle(null);
    }

    const clickEditTitleId = (id) => {
        const item = props.diagData.find((itm) => itm.id === id);
        setTitle(item.title);
        setEditTitleId(id);
    }

    const handleSaveTitle = () => {
        props.diagData.forEach(item => {
            if (item.id === editTitleId) {
                item.title = title;
            }
        });
        setTitle('');
        setEditTitleId(null);
    }

    const clickEditObsled = (sectionId, id) => {
        const section = props.diagData.find((itm) => itm.id === sectionId);
        setEditingSection(section);

        let obsled = {};
        if (id)
        {
            obsled = section.content.find((itm) => itm.id === id);
            setTitle(obsled.title);

            if (obsled.content && !obsled.content.version)
            {
                setContent(obsled.content[0].content);
            }
            else
            {
                setContent(obsled.content);
            }
        }
        setEditObsledObject(obsled);
    }

    const clickDeleteSection = (id) => {
        const item = props.diagData.find((itm) => itm.id === id);
        if (item.deleted === 1)
        {
            item.deleted = undefined;
            delete item.deleted;
        } else {
            item.deleted = 1;
        }
        setRefresh(refresh + 1);
    }

    const clickDeleteObsled = (sectionId, id) => {
        const section = props.diagData.find((itm) => itm.id === sectionId);
        const obsled = section.content.find((itm) => itm.id === id);
        if (obsled.deleted === 1)
        {
            obsled.deleted = undefined;
            delete obsled.deleted;
        } else {
            obsled.deleted = 1;
        }
        setRefresh(refresh + 1);
    }

    const handleSaveObsled = () => {
        if (editObsledObject.id)
        {
            if (!editObsledObject.new)
            {
                editObsledObject.edited = 1;
            }
            editObsledObject.title = title;
            setTitle('');
            setEditObsledObject(null);
            return;
        }

        editObsledObject.new = 1;
        editObsledObject.id = 'new-' + Math.round(Math.random() * 0x0fffffff);
        editObsledObject.sectionId = editingSection.id;
        editObsledObject.title = title;
        editingSection.content.push(editObsledObject);
        setTitle('');
        setEditObsledObject(null);
    }

    return (
        <>
            {editMethod && <>
                <AdminEditObsledMethod content={editObsledObject.content} onSave={(data)=>{
                    editObsledObject.content = data;
                    setEditMethod(false);
                }}></AdminEditObsledMethod>
            </>}
            {editObsledObject && !editMethod &&  <>
                <Container id={"edit-fenom-current"} className={'border bg-info'}>
                    <Form.Group className="mb-3">
                        <Form.Label>Заголовок</Form.Label>
                        <Form.Control as="textarea" rows={2} id={'ad_title'} onChange={event => {
                            setTitle(event.target.value);
                        }} value={title}/>
                    </Form.Group>
                    <Container>
                        <Button className={'mx-1 my-2 btn-success'} onClick={handleSaveObsled}>Сохранить методику</Button>
                    </Container>
                </Container>
                <Container>
                    <Container id={"edit-fenom-current"} className={'border'}>
                        <Container>
                            <h4>Содержимое <a href={'#'} onClick={() => setEditMethod(true)}>Редактировать</a></h4>
                        </Container>

                        <Container>
                            <DynamicText json={editObsledObject.content}></DynamicText>
                        </Container>
                   </Container>
                </Container>
                <Container className={'border bg-light'}><div id={'editorjs'}></div></Container>
            </>}
            {addNewTitle && !editMethod && <>
                <Container id={"edit-fenom-current"} className={'border bg-info'}>
                    <Form.Group className="mb-3">
                        <Form.Label>Заголовок</Form.Label>
                        <Form.Control as="textarea" rows={2} id={'ad_title'} onChange={event => {
                            setTitle(event.target.value);
                        }} value={title}/>
                    </Form.Group>
                </Container>
                <Container>
                    <Button className={'mx-1 my-2 btn-success'} onClick={handleAddTitle}>Добавить</Button>
                </Container>
            </>}
            {editTitleId && !editMethod &&
                <>
                    <Container id={"edit-fenom-current"} className={'border bg-info'}>
                        <Form.Group className="mb-3">
                            <Form.Label>Заголовок</Form.Label>
                            <Form.Control as="textarea" rows={2} id={'ad_title'} onChange={event => {
                                setTitle(event.target.value);
                            }} value={title}/>
                        </Form.Group>
                    </Container>
                    <Container>
                        <Button className={'mx-1 my-2 btn-success'} onClick={handleSaveTitle}>Сохранить</Button>
                    </Container>
                </>
            }
            {!editTitleId && !addNewTitle && !editObsledObject && !editMethod &&
                <Container>
                    <Container><a href={"#"} onClick={handleAddNewTitle}>Добавить новый раздел</a></Container>
                    {refresh != -1 &&
                    <ul className={'text-start'}>
                        {
                            props.diagData.map((d, index) =>
                            {
                                return (
                                    <li>
                                        <Container className={'highlightedBlue'}>{d.title}
                                            &nbsp; <a href={'#'}  onClick={() => clickEditTitleId(d.id)}>Редактировать</a>
                                            &nbsp; <a href={'#'}  onClick={() => clickEditObsled(d.id)}>Добавить методику</a>
                                            &nbsp; <a href={'#'}  className={'text-danger'} onClick={() => clickDeleteSection(d.id)}>{d.deleted == 1 ?
                                                'Отменить удаление (будет удален)'
                                                :
                                                'Удалить'
                                            }</a>
                                        </Container>
                                        <ul>
                                            {
                                                d.content.map((sub, index) =>
                                                {
                                                    return (
                                                            <li>{sub.title}
                                                                &nbsp;<a href={'#'} onClick={() => clickEditObsled(sub.sectionId, sub.id)}>Редактировать</a>
                                                                &nbsp; <a href={'#'}  className={'text-danger'} onClick={() => clickDeleteObsled(sub.sectionId, sub.id)}>{sub.deleted == 1 ?
                                                                    'Отменить удаление (будет удален)'
                                                                    :
                                                                    'Удалить'
                                                                }</a>
                                                            </li>
                                                        )
                                                })
                                            }
                                        </ul>
                                    </li>)
                            })
                        }
                    </ul>}
                </Container>
            }
        </>
    );
}

export default AdminEditObsled

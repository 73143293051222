import {useContext, useState} from "react";
import AdminProblems from "./admin/AdminProblems";
import {Button, Container} from "react-bootstrap";
import AdminInvite from "./admin/AdminInvite";
import AdminUsers from "./admin/AdminUsers";
import AuthContext from "../context/AuthProvider";
import AdminHypos from "./admin/AdminHypos";
import AdminProblemsConst from "./admin/AdminProblemsConst";
import AdminInstruction from "./admin/AdminInstruction";
import AdminSettings from "./admin/AdminSettings";
import AdminTeachers from "./admin/AdminTeachers";
import AdminProtocols from "./admin/AdminProtocols";
import AdminSurvey from "./admin/AdminSurvey";
import AdminWishes from "./admin/AdminWishes";
import AdminBugs from "./admin/AdminBugs";

const Admin = () => {
    const { editId } = useContext(AuthContext);

    const [adminPage, setAdminPage] = useState(1);

    return (
        <>
            {adminPage != 0 && !editId &&
                <>
                    {adminPage === 1 ?
                        <Button href={'#'} onClick={() =>
                        {
                            window.location.reload();
                        }}>Выход</Button>
                        :
                        <Button href={'#'} onClick={() =>
                        {
                            setAdminPage(1);
                        }}>Назад</Button>
                    }
                </>
            }
            {adminPage === 1 &&
                <Container className={'text-start'}>
                    <ul>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(2);}}>Инструкция</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(3);}}>Задачи</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(4);}}>Приглашения</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(5);}}>Пользователи</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(6);}}>Протоколы</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(7);}}>Опросы</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(8);}}>Пожелания</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(9);}}>Баги</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(10);}}>Банк проблем</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(11);}}>Банк гипотез и диагнозов</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(12);}}>Преподаватели</a></li>
                        <li className={'my-4'}><a href={'#'} onClick={()=>{setAdminPage(13);}}>Настройки</a></li>
                    </ul>
                </Container>
            }
            {adminPage === 2 && <AdminInstruction onSave={() => setAdminPage(1)}></AdminInstruction>}
            {adminPage === 3 && <AdminProblems></AdminProblems>}
            {adminPage === 4 && <AdminInvite></AdminInvite>}
            {adminPage === 5 && <AdminUsers></AdminUsers>}
            {adminPage === 6 && <AdminProtocols></AdminProtocols>}
            {adminPage === 7 && <AdminSurvey></AdminSurvey>}
            {adminPage === 8 && <AdminWishes></AdminWishes>}
            {adminPage === 9 && <AdminBugs></AdminBugs>}
            {adminPage === 10 && <AdminProblemsConst></AdminProblemsConst>}
            {adminPage === 11 && <AdminHypos></AdminHypos>}
            {adminPage === 12 && <AdminTeachers></AdminTeachers>}
            {adminPage === 13 && <AdminSettings onSave={() => setAdminPage(1)}></AdminSettings>}
        </>
    );
}

export default Admin

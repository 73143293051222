import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Accordion, Button, Container, Form} from "react-bootstrap";
import AdminEditFenom from "./AdminEditFenom";
import AuthContext from "../../context/AuthProvider";
import AdminEditObsled from "./AdminEditObsled";
import AdminEditPredMerop from "./AdminEditPredMerop";

const AdminEdit = (props) => {
    const { editId, setEditId } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [localProblem, setLocalProblem] = useState(null);

    const [title, setTitle] = useState('');
    const [predMethods, setPredMethods] = useState('');
    const [katamnez, setKatamnez] = useState('');
    const [determUrl, setDetermUrl] = useState('');

    const [updateProblemCount, setUpdateProblemCount] = useState(1);
    const [updateHyposCount, setUpdateHyposCount] = useState(1);

    const [fenomData, setFenomData] = useState([]);
    const [diagData, setDiagData] = useState([]);
    const [problemData, setProblemData] = useState([]);
    const [diagSumData, setDiagSumData] = useState([]);

    const [editFenom, setEditFenom] = useState(false);
    const [editObsled, setEditObsled] = useState(false);
    const [editPredMerop, setEditPredMerop] = useState(false);

    const [determUploaded, seDetermUploaded] = useState(false);
    const [determUploadedFileName, setDetermUploadedFileName] = useState(false);

    let editor = null;

    const [data, setData] = useState({
        id: props.id,
        title:'',
        predMetod: '',
        katamnez: '',
        linkSchemeDeterm: ''
    });

    async function fetchData() {
        setLocalProblem({
            right_hypos : [],
            right_problem_id  : null
        });

        const responseProblem = await axios.get(SERVER_URL + '/api/problem_const.php',
            { params: { problem:props.id } }
        );
        setProblemData(responseProblem.data);

        const responseDiagSum = await axios.get(SERVER_URL + '/api/diagnos_const.php',
            { params: { problem:props.id } }
        );
        setDiagSumData(responseDiagSum.data);

        if (props.id === -1)
        {
            return;
        }

        const response = await axios.get(SERVER_URL + '/api/problem.php',
            { params:{ problem: props.id } }
        );

        setLocalProblem({
                right_hypos : response.data.right_hypos,
                right_problem_id  : response.data.right_problem_id
        });

        setDetermUploadedFileName(response.data.determination_url)
        setData( {
            id: props.id,
            title: response.data.title,
            predMetod: response.data.pred_merop,
            katamnez: response.data.katamnez,
            linkSchemeDeterm: response.data.determination_url
        });

        setTitle(response.data.title);
        try
        {
            setPredMethods(response.data.pred_merop);
        }catch (e){
            /* Do Nothing */
        }
        setKatamnez(response.data.katamnez);
        setDetermUrl(response.data.determination_url);

        const responseFenom = await axios.get(SERVER_URL + '/api/fenomen.php',
            { params: { problem:props.id } }
        );
        setFenomData(responseFenom.data);

        const responseDiag = await axios.get(SERVER_URL + '/api/diagnos.php',
            { params: { problem:props.id } }
        );

        setDiagData(responseDiag.data);

        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleTitleChange = event => {
        const newData = Object.assign({}, data);
        newData.title = event.target.value;
        setData(newData);
        setTitle(event.target.value);
    }

    const handleKatamnezChange = event => {
        const newData = Object.assign({}, data);
        newData.katamnez = event.target.value;
        setData(newData);
        setKatamnez(event.target.value);
    }

    const saveProblem = async event => {
        const saveObject = {
            id: props.id,
            title,
            predMethods,
            katamnez,
            determUploadedFileName,
            construct: localProblem,
            fenomData,
            diagData
        };

        const responseSave = await axios.post(SERVER_URL + '/api/problem_save.php',
            saveObject
        );
        setEditId(null);

        if (props.onDone) {
            props.onDone();
        }
    }

    return (
        <>

            {editPredMerop && <>
                    <Button href={'#'} onClick={()=>{setEditPredMerop(false)}}>Назад</Button>
                    <AdminEditPredMerop content={predMethods} onSave={(data)=>{
                        setPredMethods(data);
                        setEditPredMerop(false);
                    }}></AdminEditPredMerop>
                </>
            }
            {editFenom && <>
                    <Button href={'#'} onClick={()=>{setEditFenom(false)}}>Назад</Button>
                    <AdminEditFenom id={props.id} fenomData={fenomData}></AdminEditFenom>
                </>
            }
            {editObsled && <>
                    <Button href={'#'} onClick={()=>{setEditObsled(false)}}>Назад</Button>
                    <AdminEditObsled id={props.id} diagData={diagData}></AdminEditObsled>
                </>
            }
            {!editFenom && !editObsled && !editPredMerop &&
                <Container>
                    <Button href={'#'} onClick={()=>{setEditId(null);}}>Назад</Button>
                    <h1>Основное</h1>
                    <Form.Group className="mb-3">
                        <Form.Label><h4>Запрос</h4></Form.Label>
                        <Form.Control as="textarea" onChange={handleTitleChange} rows={3} id={'ad_title'}
                                      value={title}/>
                    </Form.Group>

                    <Container>
                        <Container>
                            <h4>Феноменология <a href={'#'} onClick={() => setEditFenom(true)}>Редактировать</a></h4>
                        </Container>
                    </Container>

                    <Container>
                        <Accordion className={'mt-2'}>
                            <Accordion.Header><h4>Проблемы</h4></Accordion.Header>
                            <Accordion.Body>
                                {   updateProblemCount &&
                                    problemData.map((item, index) =>
                                        {
                                            if (item.id === localProblem.right_problem_id)
                                            {
                                                return <div className={'my-4 text-start bg-success bg-gradient text-light'}>
                                                    (Верный вариант) &nbsp; {item.title}
                                                </div>

                                            } else
                                            {
                                                return <div className={'my-4 text-start'}>
                                                    {item.title} <a href={'javascript:void(0);'} onClick={()=>{
                                                    localProblem.right_problem_id = item.id;
                                                    setUpdateProblemCount(updateProblemCount + 1);
                                                }
                                                }>Отметить правильным</a>
                                                </div>
                                            }
                                        }
                                    )
                                }
                            </Accordion.Body>
                        </Accordion>
                    </Container>

                    <Container>
                        <Accordion className={'mt-2'}>
                            <Accordion.Header><h4>Гипотезы и Диагнозы</h4></Accordion.Header>
                            <Accordion.Body>
                                {   updateHyposCount &&
                                    diagSumData.map((item, index) =>
                                        {
                                            if (localProblem.right_hypos && localProblem.right_hypos.indexOf(parseInt(item.id)) >= 0)
                                            {
                                                return <div className={'my-4 text-start bg-success bg-gradient text-light'}>
                                                    (Верный вариант) &nbsp; {item.title}&nbsp;
                                                    <a className={'text-light'} href={'javascript:void(0);'} onClick={()=>{
                                                        localProblem.right_hypos = localProblem.right_hypos.filter(i => parseInt(i) !== parseInt(item.id));
                                                        setUpdateHyposCount(updateHyposCount + 1);
                                                    }
                                                    }>Убрать выделение</a>
                                                </div>

                                            } else
                                            {
                                                return <div className={'my-4 text-start'}>
                                                    {item.title} <a href={'javascript:void(0);'} onClick={()=>{
                                                        localProblem.right_hypos.push(parseInt(item.id));
                                                        setUpdateHyposCount(updateHyposCount + 1);
                                                }
                                                }>Отметить правильным</a>
                                                </div>
                                            }
                                        }
                                    )
                                }
                            </Accordion.Body>
                        </Accordion>
                    </Container>

                    <Container>
                        <Container><h4>Данные психологического обследования <a href={'#'}  onClick={() => setEditObsled(true)}>Редактировать</a></h4></Container>
                    </Container>

                    <Form.Group className="mb-3">
                        <h4>Предложенные мероприятия <a href={'#'} onClick={() => setEditPredMerop(true)}>Редактировать</a></h4>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><h4>Катамнез</h4></Form.Label>
                        <Form.Control as="textarea" onChange={handleKatamnezChange} rows={6} id={'ad_katamnez'}
                                      value={katamnez}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><h4>Схему детерминации</h4></Form.Label>
                        {determUploaded &&
                            <div>Загружено успешно</div>
                        }
                        {determUploadedFileName &&
                            <div>
                                <img src={determUploadedFileName}/>
                            </div>
                        }
                        <div>
                            <input type="file" id="file_determ" name="file_determ" accept=".jpg, .jpeg, .png"/>
                        </div>
                            <div>
                                <button onClick={() => {
                                    seDetermUploaded(false);

                                    var formData = new FormData();
                                    var imagefile = document.querySelector('#file_determ');
                                    formData.append("id", props.id );
                                    formData.append("file_determ", imagefile.files[0]);
                                    axios.post('/api/upload_determ.php', formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }).then((responseUploadDeterm)=> {
                                        if (responseUploadDeterm.statusText === 'OK')
                                        {
                                            seDetermUploaded(true);
                                            setDetermUploadedFileName(responseUploadDeterm.data.name);
                                        }
                                    });
                                }}>Отправить</button>
                            </div>
                    </Form.Group>

                    <Container className={'mt-5'}>
                        <Container className={'my-4'}>
                            <Button className={'mx-1 btn-success'} onClick={saveProblem}>Сохранить</Button>
                        </Container>
                    </Container>

                </Container>
            }
        </>
    );
}

export default AdminEdit

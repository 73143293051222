export default class Problems
{
    constructor(events, beforeChangeProblem)
    {
        this.events = events;
        this.beforeChangeProblem = beforeChangeProblem;
        this.problems = [];
        this.problems.push({page: 1, events: this.events.get()});
        this.currentProblem = 1;
    }

    tryToApply(event)
    {
        const numberProblem = event.message.match(/open\stask.*page(&quot;|u0022):(\d+)/)
        if (numberProblem && numberProblem[2] != this.currentProblem)
        {
            if (this.beforeChangeProblem)
            {
                this.beforeChangeProblem();
            }

            this.events.inc();
            this.problems.push({page: numberProblem[2], events: this.events.get()});
            this.currentProblem = numberProblem[2];
        }
    }

    get()
    {
        return this.problems;
    }

}

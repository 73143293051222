import CumulativeProcessor from "../base/CumulativeProcessor";

export default class PsyDeterm extends CumulativeProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Схема детерминации';

        this.beginEvents = [
            'chapter&quot;:5,',
            'chapteru0022:5,'
        ];
        this.endEvents = [
            'Psy determ, click back',
            'Psy determ, click Main menu',
            'Psy determ, click Apply',
            'View Instruction',
            'window closed',
            'invite_expired'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);
        if (
            (
                this.contains(event, 'Login') ||
                this.contains(event, 'focused window')
            )
            && this.startTime)
        {
            this.end(this.prevEvent);
        }
        this.prevEvent = event;
    }
}


import BaseProcessor from "../base/BaseProcessor";

export default class TotalTime extends BaseProcessor
{
    constructor(events)
    {
        super(events);
        this.startTime = null;
        this.totalTime = null;
        this.prevEvent = null;
        this.prevPrevEvent = null;
        this.prevPrevPrevEvent = null;
        this.sumTime = 0;
        this.wasFullRight = false;

        this.taskEvent = null;
        this.prevTaskEvent = null
    }

    tryToApply(event)
    {
        if (!this.startTime)
        {
            this.startTime = new Date(event.timestamp);
        }
        else
        {
            if (
                this.contains(event, 'fullRight') &&
                !this.contains(event, '&quot;fullRight&quot;') &&
                !this.contains(event, 'u0022fullRightu0022')
            )
            {
                this.totalTime = new Date(event.timestamp) - this.startTime;
                this.sumTime += this.totalTime;
                this.wasFullRight = true;
            }

            if (!this.wasFullRight &&
                this.contains(event, 'open task')
            )
            {
                this.prevTaskEvent = this.taskEvent;
                this.taskEvent = event;
                if (this.prevTaskEvent && this.taskEvent)
                {
                    const deltaTime = new Date(this.taskEvent.timestamp) - new Date(this.prevTaskEvent.timestamp);
                    if (deltaTime > 30 * 60 * 1000)
                    {
                        this.startTime = null;
                        this.totalTime = null;
                    }
                }
            }

            if (
                !this.wasFullRight &&
                (
                    this.contains(event,  'window closed')
                )
            )
            {
                this.totalTime = new Date(event.timestamp) - this.startTime;
                this.sumTime += this.totalTime;
                this.startTime = null;
                this.totalTime = null;
            }

            if (!this.wasFullRight &&
                (
                    this.contains(event,  'Login') ||
                    this.contains(event,  'focused window')
                )
            )
            {
                this.totalTime = new Date(this.prevEvent.timestamp) - this.startTime;
                this.sumTime += this.totalTime;
                this.startTime = null;
                this.totalTime = null;
            }

            if (!this.wasFullRight &&
                (
                    this.contains(event,  'invite_expired')
                )
            )
            {
                this.totalTime = new Date(this.prevPrevEvent.timestamp) - this.startTime;
                this.sumTime += this.totalTime;
                this.startTime = null;
                this.totalTime = null;
            }
        }
        this.prevPrevPrevEvent = this.prevPrevEvent;
        this.prevPrevEvent = this.prevEvent;
        this.prevEvent = event;
    }

    get()
    {
        return this.sumTime;
    }

    changingProblem()
    {
        if (!this.wasFullRight && this.startTime && this.prevEvent && !this.totalTime)
        {
            this.totalTime = new Date(this.prevEvent.timestamp) - this.startTime;
            this.sumTime += this.totalTime;
        }
        this.events.push(
            {
                name: 'Время решения задачи',
                time: this.sumTime
            }
        );
        this.startTime = null;
        this.totalTime = null;
        this.sumTime = 0;
        this.wasFullRight = false;
    }

}
